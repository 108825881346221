import styled from 'styled-components';

export const NavbarDiv = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.6em;
	padding: 1em 0;
	/* justify-content: space-around; */
	align-items: center;

	.logo-section {
		/* a {
			display: block;
			width: 50%;
		} */

		img {
			width: 35%;
			object-fit: contain;
		}
	}

	nav {
		display: flex;
		justify-content: space-around;
		align-items: center;

		.ant-dropdown-menu {
			width: 20vw;
		}
	}
	.divider {
		height: 6vh;
		border: 1px solid var(--grey-4);
	}
	.navbar-button {
		background: none;
		cursor: pointer;
		margin: 10px 20px;
		border: none;
		font-weight: 600;
		color: var(--grey-5);
	}
	.navbar-button-icon {
		margin: 0 0.5em;
		font-size: x-large;
	}
	img {
		transition: 0.5s ease;
	}
	img:hover {
		transform: scale(1.02);
	}
	button:focus,
	button:hover {
		color: black;
	}
	button > span {
		font-size: x-large;
	}
	a {
		color: var(--grey-5);
	}

	@media screen and (max-width: 900px) {
		.logo-section {
			display: flex;
			justify-content: center;
			align-items: center;
			order: 1;
			margin-bottom: 0.5em;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		nav {
			order: 2;

			.ant-dropdown-menu {
				width: 'auto';
			}
		}
	}

	@media screen {
	}

	@media screen and (min-width: 1024px) {
		flex-direction: row;
		justify-content: space-around;
		gap: 1.5em;

		.logo-section {
			flex: 0.11;

			a {
				width: 100%;

				img {
					width: 100%;
					object-fit: contain;
				}
			}
		}

		nav {
			flex: 0.28;
		}
	}

	@media (max-width: 440px) {
		.navbar-button {
			margin: 5px;
		}
		.cart-badge {
			sup {
				margin-top: 0px !important;
				right: 0px !important;
			}
		}
		.navbar-button-icon {
			font-size: large;
		}
	}
`;

export const SearchDiv = styled.div`
	/* width: 40vw; */
	/* input::placeholder {
		font-weight: 500;
	} */
	/* .ant-input-wrapper {
		height: 6vh;
		> input {
			height: 100%;
			border-radius: 1em;
			width: 100%;
			border: 1px solid var(--blue-3);
		}
		> span {
			height: 100%;
			width: 15%;
		}
		.ant-btn {
			height: 100%;
			width: 100%;
			border-radius: 0 1em 1em 0 !important;
			background: var(--blue-3);
			:hover {
				color: var(--white);
			}
		}
	} */

	@media screen and (max-width: 900px) {
		order: 3;
		width: 80%;
		margin: 0.5em;
	}

	@media screen and (min-width: 1024px) {
		flex: 0.5;
	}
`;

export const CategorybarDiv = styled.div`
	background: var(--light-blue-4);
	width: 100%;
	height: 8vh;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		background: none;
		cursor: pointer;
		margin: 0.9em;
		border: none;
		font-weight: 600;
	}
	nav {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		/* overflow-x: scroll; */
	}
	nav a {
		color: black;
	}

	@media screen and (min-width: 1024px) {
		button {
			margin: 10px 20px;
		}
	}
`;

export const StyledSearchInput = styled.div`
	display: flex;
	max-width: 100%;
	min-width: 100%;
	border: 0.1em solid var(--blue);
	border-radius: 0.895em;
	gap: 0.2em;

	.search-input-div {
		width: 70%;

		input {
			width: 100%;
			border: none;
			padding: 1em 0.5em;
			border-top-left-radius: 0.895em;
			border-bottom-left-radius: 0.895em;
			background: transparent;

			:focus {
				outline: none;
			}
		}
	}

	.search-select-div {
		width: 20%;
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			width: 100%;
			border: none;
			padding: 1.03em 0.5em;
			background: var(--blue);
			color: var(--white);
			text-transform: capitalize;
			cursor: pointer;
			text-align: center;
			text-overflow: ellipsis;
		}
	}

	.search-btn-div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10%;
		background: var(--blue);
		border-top-right-radius: 0.59em;
		border-bottom-right-radius: 0.59em;

		button {
			width: 100%;
			height: 100%;
			border: none;
			background: none;
			color: var(--white);
			font-size: 1.4em;
			cursor: pointer;

			:hover {
				color: var(--white);
			}
		}
	}
`;

export const SearchResultsWrapper = styled.div`
	position: absolute;
	left: 5%;
	top: 100%;
	width: 90%;
	z-index: 5;
	/* transform: translateX(90%); */
	background: var(--white);
	padding: 1em;
	box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.15);
	border-radius: 1em;

	@media screen and (min-width: 1024px) {
		left: 40%;
		width: 40%;
		transform: translateX(-40%);
		top: 6em;
		padding: 2em;
	}
`;

export const VendorSearchResultsWrapper = styled.div`
	.showing-text {
		::first-letter {
			text-transform: uppercase;
		}

		span {
			color: var(--blue);
			font-weight: bold;
		}
	}

	.show-all-results {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			background: transparent;
			border: none;
			text-transform: capitalize;
			font-weight: 500;
		}
	}
`;

export const VendorSearchResultCardWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	gap: 1em;
	cursor: pointer;
	padding: 0.8em;

	:hover {
		background: #f9f9f9;
		border-radius: 0.5em;
	}

	.search-vendor-img {
		flex: 0.2;
		height: 6em;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 0.5em;
		}
	}

	.search-vendor-description {
		flex: 0.5;

		h3 {
			font-size: 1.3em;
			margin-bottom: 0.4em;
			text-transform: capitalize;
		}

		.vendor-location {
			display: flex;
			align-items: center;
			gap: 0.5em;

			span:nth-child(2) {
				::first-letter {
					text-transform: uppercase;
				}
			}
		}
	}

	.search-vendor-view-store-btn {
		flex: 0.3;

		a {
			display: block;
			border: 0.1em solid var(--blue);
			text-align: center;
			text-transform: capitalize;
			color: var(--blue);
			font-size: 0.9em;
			padding: 0.5em;
			font-weight: 500;
			border-radius: 0.186em;
			width: 100%;
		}
	}

	@media screen and (min-width: 1024px) {
		.search-vendor-description {
			flex: 0.6;
		}

		.search-vendor-view-store-btn {
			flex: 0.2;
		}
	}
`;

export const ProductSearchResultsWrapper = styled.div`
	.showing-text {
		::first-letter {
			text-transform: uppercase;
		}

		span {
			color: var(--blue);
			font-weight: bold;
		}
	}

	.show-all-results {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			background: transparent;
			border: none;
			text-transform: capitalize;
			font-weight: 500;
		}
	}
`;

export const ProductSearchResultCardWrapper = styled.div`
	display: flex;
	/* flex-direction: column; */
	gap: 1em;
	margin-bottom: 1em;
	padding: 0.8em;
	cursor: pointer;

	:hover {
		background: #f9f9f9;
		border-radius: 0.5em;
	}

	.product-search-img {
		flex: 0.3;
		height: 6em;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 0.5em;
		}
	}

	.product-search-content {
		flex: 0.7;

		.product-name-and-price {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			/* justify-content: space-between; */
			/* align-items: center; */

			h3 {
				font-size: 1.2em;
				text-transform: capitalize;
			}
		}

		.product-sizes {
			display: flex;
			flex-direction: column;
			gap: 0.5em;

			> span::after {
				content: '|';
				margin-left: 0.2em;
			}
		}

		.product-size-list {
			display: flex;
			align-items: center;
			gap: 0.3em;

			ul {
				display: flex;
				margin: 0;
				padding: 0;
				gap: 0.5em;
				list-style: none;

				li {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 2em;
					width: 2em;
					border: 0.1em solid black;
					text-align: center;
					text-transform: uppercase;
					font-size: 0.9em;
					border-radius: 0.25em;
				}
			}
		}
	}

	.product-search-add-to-cart {
		flex: 0.1;

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--white);
			border: 0.08em solid var(--black);
			border-radius: 0.25em;
			font-size: 1.3em;
			padding: 0.6em;
		}
	}

	@media screen and (min-width: 900px) {
		.product-search-img {
			flex: 0.2;
		}

		.product-search-content {
			.product-name-and-price {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.product-sizes {
				flex-direction: row;
				align-items: center;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		flex-direction: row;
		align-items: center;
	}
`;

export const DownloadAppWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--blue);
	padding: 0.8em 0;

	> div {
		display: flex;
		align-items: center;
		gap: 0.5em;
		flex-direction: column;
		p {
			color: var(--white);
			font-size: 1.1em;
			margin: 0;
			font-weight: 500;
		}

		a {
			border: 0.1em solid var(--white);
			padding: 0.5em 0.8em;
			border-radius: 0.2em;
			color: var(--white);
			text-transform: capitalize;
			font-weight: 500;
		}
	}

	@media screen and (min-width: 991px) {
		> div {
			flex-direction: row;
		}
	}
`;