import { DownloadAppWrapper } from './styles'

const DownloadAppNotificationBar = () => {
	const googlePlayUrl =
		'https://play.google.com/store/apps/details?id=com.product.copnow';

	const appStoreUrl =
		'https://apps.apple.com/ng/app/copnow-look-good-everywhere/id6448952151';

	let storeUrl;
	let label;

	const userAgent = navigator.userAgent;

	switch (true) {
		case /Mac|Mac OS X /i.test(userAgent):
			storeUrl = appStoreUrl;
			label = 'Get our iOS app!';
			break;

		case /Windows/i.test(userAgent):
			storeUrl = googlePlayUrl;
			label = 'Download our Android app';
			break;

		case /iPhone|ipad/i.test(userAgent):
			storeUrl = appStoreUrl;
			label = 'Get our iOS app!';
			break;

		case /Android/i.test(userAgent):
			storeUrl = googlePlayUrl;
			label = 'Get our Android app';
			break;

		default:
			storeUrl = googlePlayUrl;
			label = 'Download our app';
	}

	return (
		<DownloadAppWrapper>
			<div>
				<p>Now available! {label} 📱</p>
				<a href={storeUrl} target="_blank" rel="noreferrer">
					download
				</a>
			</div>
		</DownloadAppWrapper>
	);
};

export default DownloadAppNotificationBar;
