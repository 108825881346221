import styled from 'styled-components';

export const StyledWrapper = styled.div`
	margin: 5em 0;

	.app-banner-section {
		position: relative;
		display: flex;
		flex-direction: column;
		background: var(--blue);
		border-radius: 1em;

		.app-banner-pattern {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.app-banner-section-left {
			/* width: 50%; */
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-left: 5em;
			position: relative;
			z-index: 2;
			order: 2;

			h2 {
				text-transform: capitalize;
				font-weight: 900;
				font-size: 2.2em;
				color: var(--white);
				margin: 0.2em 0;
				text-align: center;
			}

			p {
				font-size: 1.2em;
				font-weight: 500;
				color: var(--white);
				text-align: center;

				&::first-letter {
					text-transform: uppercase;
				}
			}

			.download-app-btns {
				display: flex;
				gap: 1em;
				flex-direction: column;
			}
		}

		.app-banner-section-right {
			/* width: 50%; */
			position: relative;
			z-index: 2;
			order: 1;

			.phone-mockup-img {
				position: relative;
				top: 4em;
				width: 80%;
				margin-top: -10em;

				img {
					width: 100%;
					object-fit: contain;
				}
			}
		}

		@media screen and (max-width: 990px) {
			.app-banner-section-left {
				display: flex;
				justify-content: center;
				padding: 2em 0;
				margin-top: 3em;

				h2 {
					width: 80%;
				}

				.download-app-btns {
					align-items: center;
					a {
						width: 70%;
						display: flex;

						img {
							width: 100%;
							object-fit: contain;
						}
					}
				}
			}

			.app-banner-section-right {
				display: flex;
				justify-content: center;
			}
		}

		@media screen and (min-width: 991px) {
			/* display: flex; */
			flex-direction: row;

			.app-banner-section-left {
				width: 50%;
				align-items: flex-start;
				order: 1;

				h2 {
					font-size: 2.5em;
				}

				p {
					text-align: left;
				}

				.download-app-btns {
					flex-direction: row;
				}
			}

			.app-banner-section-right {
				width: 50%;
				order: 2;

				.phone-mockup-img {
					width: 90%;
				}
			}
		}
	}
`;
