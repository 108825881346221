import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { ProductSearchResultCardWrapper } from '../../styles';

import sample from '../../../../assets/images/new_hero_imgs/1.jpg';

const ProductSearchResultCard = ({ productDetails }) => {
	const { id, product_name, product_price, main_image, sizes } = productDetails;
	const navigate = useNavigate();

	const redirectToProductDetail = (id, productName) => {
		return navigate(`/products/${id}/${productName}`, {
			replace: true,
		});
	};

	return (
		<ProductSearchResultCardWrapper
			onClick={() => redirectToProductDetail(id, product_name)}
		>
			<div className="product-search-img">
				<img src={main_image || sample} alt="" />
			</div>
			<div className="product-search-content">
				<div className="product-name-and-price">
					<h3>{product_name || 'blue native gown'}</h3>
					<h3>N{product_price || '7,000'}</h3>
				</div>
				<div className="product-sizes">
					<span>available</span>
					<div className="product-size-list">
						<span>sizes</span>
						<ul>
							{sizes?.length ? (
								sizes.map((size, index) => <li key={index}>{size}</li>)
							) : (
								<li>-</li>
							)}
						</ul>
					</div>
				</div>
			</div>
			<div className="product-search-add-to-cart">
				<button>
					<HiOutlineShoppingCart />
				</button>
			</div>
		</ProductSearchResultCardWrapper>
	);
};

export default ProductSearchResultCard;

// PROPTYPES
ProductSearchResultCard.propTypes = {
	productDetails: PropTypes.object,
};
