import Axios from './Axios';
import { Error } from '../shared/Alerts';

function VendorService() {
	const vendorUrl = '/vendor';
	const vendorDashboardUrl = vendorUrl + '/dashboard';
	const vendorStoreUrl = vendorDashboardUrl + '/store';

    const getAllVendors = async params => {
        try {
            const { data } = await Axios.get(vendorUrl + '/list', { params })
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching Vendors')
            return false
        }
    }

    const getVendor = async params => {
        const { store_username, vendor_id } = params
        if (!store_username && !vendor_id) {
            Error(null, {}, "No Vendor credentials received.")
            return false
        } 
        try {
            const { data } = await Axios.get(vendorUrl + `/get-single`, { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Vendor details.")
            return false
        }
    }

    const getVendorVettedProducts = async (id, params) => {
        try {
            const { data } = await Axios.get(vendorUrl + `/${id}/get-vetted-products`, { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Vendor vetted products.")
            return false;
        }
    }

    const getVendorFeaturedProducts = async (id, params) => {
        try {
            const { data } = await Axios.get(vendorUrl + `/${id}/get-featured-products`, { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Vendor vetted products.")
            return false;
        }
    }

    const getVendorStores = async params => {
        try {
            const { data } = await Axios.get(vendorUrl + '/stores/list', { params })
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching Vendor stores')
            return false
        }
    }

    const getVendorProducts = async params => {
        try {
            const { data } = await Axios.get(vendorDashboardUrl + '/products', { params })
            return data
        } catch (error) {
            Error(error, {}, 'Error retrieving vendor products.')
            return false
        }
    }

    const getVendorProductDetail = async (productId) => {
        try {
            const { data } = await Axios.get(vendorDashboardUrl + '/products/' + productId)
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching product.')
            return false
        }
    }

    const getVendorOrders = async (params = {}) => {
        try {
            const { data } = await Axios.get(vendorDashboardUrl + '/orders', { params })
            return data
        } catch (error) {
            Error(error, {}, 'Error retrieving vendor orders.')
            return false
        }
    }

    const createVendor = async params => {
        try {
            const { data } = await Axios.post(vendorUrl + '/create', params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const createVendorStore = async params => {
        try {
            const { data } = await Axios.post(vendorUrl + '/store/create', params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const rateVendor = async (vendorId, params) => {
        const { rating } = params
        if (!vendorId || !rating) {
            Error(null, {}, "Invalid request")
            return false
        }
        try {
            const { data } = await Axios.put(vendorUrl + `/rating/${vendorId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const getVendorDetails = async () => {
        try {
            const { data } = await Axios.get(vendorDashboardUrl + '/details')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching vendor details')
            return false
        }
    }

    const updateVendorDetails = async (vendorId, params) => {
        const { full_name, email, phone_1, date_of_birth, home_address, state_id, local_government_id } = params
        if (!vendorId || !full_name || !email || !phone_1 || !date_of_birth || !home_address || !state_id || !local_government_id) {
            Error(null, {}, "Incomplete details supplied")
            return false
        }
        try {
            const { data } = await Axios.put(vendorDashboardUrl + `/update/${vendorId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const deactivateVendorAccount = async (vendorId, params) => {
        const { status } = params
        if (!vendorId || !status || status !== 0) {
            Error(null, {}, "Invalid details supplied")
            return false
        }
        try {
            const { data } = await Axios.put(vendorDashboardUrl + `/deactivate-vendor/${vendorId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const activateVendorAccount = async (vendorId, params) => {
        const { status } = params
        if (!vendorId || !status || status !== 1) {
            Error(null, {}, "Invalid details supplied")
            return false
        }
        try {
            const { data } = await Axios.put(vendorDashboardUrl + `/activate-vendor/${vendorId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const addDeliverableStatesToVendor = async params => {
        const { state_id, price } = params
        if (!state_id || !price) {
            Error(null, {}, "Incomplete details supplied")
            return false
        }
        try {
            const { data } = await Axios.post(vendorDashboardUrl + '/add-deliverable-state', params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const removeDeliverableStatesFromVendor = async deliverableId => {
        if (!deliverableId) {
            Error(null, {}, 'No ID supplied.')
            return false
        }
        try {
            const { data } = await Axios.delete(vendorDashboardUrl + `/remove-deliverable-state/${deliverableId}`)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const getVendorStoreDetails = async () => {
        try {
            const { data } = await Axios.get(vendorStoreUrl + '/details')
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Vendor Store details.")
            return false
        }
    }

    const updateVendorStoreDetails = async (vendorStoreId, params) => {
        try {
            const { data } = await Axios.post(vendorStoreUrl + `/update/${vendorStoreId}`, params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const toggelVendorStoreStatus = async (vendorStoreId, params) => {
        const { status } = params
        if (!vendorStoreId || ![1, 0].includes(status)) {
            Error(null, {}, "Invalid details supplied")
            return false
        }
        try {
            const { data } = await Axios.put(vendorStoreUrl + `/toggle-active/${vendorStoreId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const addFeaturedItemToStoreAccount = async params => {
        const { product_id } = params
        if (!product_id) {
            Error(null, {}, 'Invalid details supplied')
            return false
        }
        try {
            const { data } = await Axios.post(vendorStoreUrl + '/add-feature-item', params)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const removeFeaturedItemFromStoreAccount = async featuredItemId => {
        if (!featuredItemId) {
            Error(null, {}, "Invalid parameters supplied.")
            return false
        }
        try {
            const { data } = await Axios.delete(vendorStoreUrl + `/remove-feature-item/${featuredItemId}`)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const getVendorSoldItems = async () => {
        try {
            const { data } = await Axios.get(vendorStoreUrl + '/sold-items')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching sold items')
            return false
        }
    }

    const getVendorRevenue = async () => {
        try {
            const { data } = await Axios.get(vendorStoreUrl + '/revenue')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching revenue stats')
            return false
        }
    }

    const getVendorStatisticsReport = async params => {
        const { type } = params
        if (!type || !['pdf', 'mail'].includes(type)) {
            Error(null, {}, 'Invalid request data')
            return false
        }
        try {
            await Axios.get(vendorStoreUrl + '/statistics-export', { params })
            return  true
        } catch (error) {
            Error(error, {}, "Error fetching Vendor statistics.")
            return false
        }
    }

	const searchForVendor = async (query) => {
		try {
			const { data } = await Axios.get(`/search/vendor?search=${query}`);
			console.log(data);
			return data;
		} catch (error) {
			console.log(error);
			Error('something went wrong');
			return false;
		}
	};

	return {
		getAllVendors,
		getVendor,
		getVendorStores,
		createVendor,
		createVendorStore,
		rateVendor,
		getVendorDetails,
		updateVendorDetails,
		deactivateVendorAccount,
		activateVendorAccount,
		addDeliverableStatesToVendor,
		removeDeliverableStatesFromVendor,
		getVendorStoreDetails,
		updateVendorStoreDetails,
		toggelVendorStoreStatus,
		addFeaturedItemToStoreAccount,
		removeFeaturedItemFromStoreAccount,
		getVendorSoldItems,
		getVendorRevenue,
		getVendorStatisticsReport,
		getVendorProducts,
		getVendorOrders,
		searchForVendor,
        getVendorVettedProducts,
        getVendorFeaturedProducts,
        getVendorProductDetail
	};
}

export default VendorService;
