import styled from 'styled-components';
import backgroundImage1 from '../../assets/images/dark-skinned-female-student-happy.jpg';
import hero1 from '../../assets/images/new_hero_imgs/1.jpg';
import hero2 from '../../assets/images/new_hero_imgs/3.jpg';
import hero3 from '../../assets/images/new_hero_imgs/4.jpg';

export const HomepageWrapper = styled.div`
	min-height: 80vh;
	/* overflow: hidden; */
`;

export const HeroSectionWrapper = styled.div`
	width: 100%;
	height: 600px;
	display: flex;
	padding: 1em;
	.left {
		position: relative;
		width: 55%;
		background-image: url(${hero1});
		background-size: cover;
		background-repeat: no-repeat;
		margin: 1em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 1em 2em;
		animation: slideInLeft;
		animation-duration: 1s;
	}
	.right {
		width: 45%;
		height: 100%;
		display: flex;
		flex-direction: column;
		animation: slideInRight;
		animation-duration: 1s;
		.top,
		.bottom {
			position: relative;
			height: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			margin: 1em;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 1em 2em;
		}
		.top {
			background-image: url(${hero2});
		}
		.bottom {
			background-image: url(${hero3});
		}
	}
	h1,
	h3,
	p,
	small {
		color: var(--white);
		z-index: 1;
	}
	h1,
	h3 {
		font-weight: 600;
		text-transform: uppercase;
	}

	p {
		font-size: 1.1em;
	}

	small {
		font-size: 1em;
	}

	button {
		background: none;
		border: 1px solid var(--white);
		padding: 0.5em 1em;
		color: aliceblue;
		font-weight: 600;
		border-radius: 0.2em;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}
	button:hover {
		transform: scale(1.02);
	}
	@media screen and (max-width: 770px) {
		display: block;
		height: auto;
		/* flex-direction: column; */
		/* height: 100vh; */
		.left,
		.right {
			display: none;
			width: 100%;
			height: 50%;
			margin: 0.5em;
		}
		.left {
			padding: 0.5em 1em;
		}
		.top,
		.bottom {
			width: 100%;
			margin: 0.5em 0 !important;
			padding: 0.5em 1em !important;
		}
	}
`;

export const OurCategoriesWrapper = styled.div`
	width: 100%;
	padding: 2em;
	margin: 0.5em;
	h4 {
		font-weight: 600;
		margin: 0.5em;
		text-align: center;
	}
`;

export const CategoriesContainer = styled.div`
	width: 90%;
	margin: 3em auto;
	.category-descriptions {
		border-radius: 0.5em;
		background: var(--grey-16);
		font-weight: 600;
		padding: 3em 5em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		h3 {
			font-weight: 600;
		}
		small {
			color: var(--grey-5);
		}
	}
	.category-image {
		width: 100%;
		height: 100%;
		position: relative;
		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
			border-radius: 0.5em;
		}
		.category-nav-button {
			position: absolute;
			z-index: 99999;
			background: none;
			border: none;
			color: white;
			font-weight: 600;
			font-size: 0.9em;
			left: 40%;
			bottom: 10%;
			transition: all 0.5s ease;
			:hover {
				transform: scale(1.05);
			}
		}
		
	}
	.grid {
		display: grid;
		grid-template-rows: 1fr;
		grid-column-gap: 2px;
		grid-row-gap: 0px;
		margin: 0.2em 0;
	}
	.double {
		grid-template-columns: repeat(2, 1fr);
	}
	.triple {
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (max-width: 600px) {
		.double {
			grid-template-columns: repeat(1, 1fr);
		}
		.triple {
			grid-template-columns: repeat(1, 1fr);
		}
	}
`

export const SelectedItemsWrapper = styled.div`
	width: 100%;
	padding: 2em;
	margin: 0.5em;
	h4 {
		font-weight: 600;
		margin: 0.5em;
		text-align: center;
	}
`;

export const SectionDescriptionDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const WhyUseCopnowWrapper = styled.div`
	width: 100%;
	background: var(--grey-8);
	padding: 10px;
	h4 {
		font-weight: 600;
		text-align: center;
		margin: 0.5em 0;
	}
	h6 {
		font-weight: 600;
		margin: 0.5em;
	}
	p {
		text-align: center;
		font-size: smaller;
	}
`;

export const WhyUseCopnowOuterDiv = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	/* justify-content: space-evenly; */
	/* align-items: center; */
	padding: 10px;

	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const WhyUseCopnowInnerDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* width: 25%; */
	padding: 0.2em;
`;

export const TopItemsWrapper = styled.div`
	width: 100%;
	padding: 2em;
	margin: 0.5em;
	h4 {
		font-weight: 600;
		margin: 0.5em;
	}
	p {
		margin: 0.5em;
	}
`;

export const AdvertDiv = styled.div`
	position: relative;
	width: 100%;
	height: 400px;
	background-image: url(${backgroundImage1});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h2 {
		color: var(--white);
		z-index: 1;
		font-weight: 600;
		text-align: center;
	}
	button {
		border: 2px solid var(--white);
		border-radius: 60px;
		padding: 0.8em;
		box-sizing: border-box;
		background: none;
		color: var(--white);
		width: 8.8em;
		font-weight: 600;
		z-index: 1;
		margin: 0.5em;
		transition: 0.5s ease-in-out;
	}
	button:hover {
		transform: scale(1.05);
	}
`;

export const AdvertOverlay = styled.div`
	position: absolute;
	top: 0;
	/* bottom: 0; */
	/* right: 0; */
	left: 0;
	background: var(--black);
	height: 100%;
	width: 100%;
	opacity: 0.6;
	pointer-events: none;
`;

export const HeroCarousel = styled.div`
	display: none;
	.slide-one,
	.slide-two,
	.slide-three {
		display: flex;
		height: 35vh;
		position: relative;
		background-size: cover;
	}

	.slide-two {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-image: url(${hero2});
	}

	.slide-three {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-image: url(${hero3});
		/* background-size: cover; */
	}

	@media screen and (max-width: 600px) {
		display: block;
	}
`;
