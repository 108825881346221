import axios from 'axios';
import TokenService from './TokenService';

const { getToken } = TokenService();

let url = process.env.REACT_APP_API_URL;

let headers = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*'
};

const Axios = axios.create({
	baseURL: url,
	headers,
});

Axios.interceptors.request.use((config) => {
	config.headers.authorization = 'Bearer ' + getToken();

	return config;
});

export const BlogAxios = axios.create({
	baseURL: 'https://oyster-app-to7fk.ondigitalocean.app/api/',
	// baseURL: 'http://localhost:1337/api/',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

export default Axios;
