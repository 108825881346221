import React, { Suspense, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import TokenService from '../services/TokenService';
import ActivitySpinner from '../shared/ActivitySpinner';
import { Error } from "../shared/Alerts";
import PageNotFound from '../views/PageNotFound/PageNotFound';

const { getToken, isTokenExpired, removeToken } = TokenService()
const EXPIRED = 'expired';
const INVALID = 'invalid';
const VALID = 'valid';

const Guard = ({
    component: Component,
    routeRedirect,
    ...rest
}) => {
    const { pathname } = useLocation()
    const [tokenStatus, setTokenStatus] = useState(VALID)
    let mounted = useRef(true)

    const navigate = () => mounted.current ? (<Navigate to={{ pathname: routeRedirect }} state={{ from: pathname }} replace={true}/>) : (<PageNotFound />);

    useEffect(() => {
        mounted.current = true

        const token = getToken()
        if (token && isTokenExpired()) {
            Error(null, {}, 'Token expired. Please login again.')
            removeToken()
            setTokenStatus(EXPIRED)
        } else if (!token) {
            Error(null, {}, 'Please login.')
            setTokenStatus(INVALID)
        }

        return () => {
            setTokenStatus(VALID)
            mounted.current = false
        }
    }, [pathname])

    if ([EXPIRED, INVALID].includes(tokenStatus)) {
        return navigate();
    } else {
        return (
            <Suspense fallback={<ActivitySpinner style={{ height: '80vh' }} size="large"/>}>
                <Component {...rest}/>
            </Suspense>
        )
    }
}

Guard.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    routeRedirect: PropTypes.string.isRequired,
    rest: PropTypes.any
}

Guard.defaultProps = {
    routeRedirect: "/auth/login"
}
 
export default Guard;