import { Link, useLocation } from 'react-router-dom';
import React, { Fragment } from 'react';
import {
	FacebookFilled,
	LinkedinFilled,
	InstagramFilled,
	TwitterCircleFilled,
} from '@ant-design/icons';
import Container from '../../shared/Container';
import {
	FooterWrapper,
	FooterContainer,
	FooterCount,
	FooterIcons,
	FooterCopyright,
	FooterGrid,
	FooterGridItem,
} from './styles';
import logo from '../../assets/images/copnow-logo.png';

const getYear = () => new Date().getFullYear();

const pathsToDisableFooter = ['auth', 'vendor', 'user', 'admin'];

const Footer = () => {
	const { pathname } = useLocation();

	const toggleFooter = () => {
		const path = pathname.split('/').filter(Boolean)[0];

		return pathsToDisableFooter.includes(path) ? false : true;
	};

	return toggleFooter() ? (
		<Fragment>
			<FooterWrapper>
				<Container width="90%">
					<FooterGrid>
						<FooterGridItem>
							<FooterContainer>
								<img src={logo} alt="Copnow Logo" />
							</FooterContainer>
						</FooterGridItem>
						<FooterGridItem>
							<FooterContainer>
								<h5>help</h5>
								<ul>
									<li>
										<Link to="/faqs">help center</Link>
									</li>
								</ul>
							</FooterContainer>
						</FooterGridItem>
						<FooterGridItem>
							<FooterContainer>
								<h5>contact</h5>
								<ul>
									<li>
										<Link to="/contact-us">contact us</Link>
									</li>
									<li>
										<Link to="/faqs">FAQs</Link>
									</li>
								</ul>
							</FooterContainer>
						</FooterGridItem>
						<FooterGridItem>
							<FooterContainer>
								<h5>useful links</h5>
								<ul>
									<li>
										<Link to="/auth/login">sign in</Link>
									</li>
									<li>
										<Link to="/sell">buy/sell on copnow</Link>
									</li>
									<li>
										<Link to="/terms-and-conditions">terms and conditions</Link>
									</li>
									<li>
										<Link to="/privacy-policy">privacy and policy</Link>
									</li>
								</ul>
							</FooterContainer>
						</FooterGridItem>
						<FooterGridItem>
							<FooterContainer>
								<h5>meet copnow</h5>
								<ul>
									<li>
										<Link to="/about">about copnow</Link>
									</li>
									<li>
										<Link to="/blog">blog</Link>
									</li>
									<li>
										<Link to="/become-an-ambassador">become an ambassador</Link>
									</li>
								</ul>
							</FooterContainer>
						</FooterGridItem>
					</FooterGrid>
					<FooterIcons>
						<a target="_blank" rel="noreferrer" href="https://fb.me/copnowhq">
							{' '}
							<i>
								<FacebookFilled />
							</i>{' '}
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.linkedin.com/company/copnow"
						>
							{' '}
							<i>
								<LinkedinFilled />
							</i>{' '}
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://instagram.com/copnowhq"
						>
							{' '}
							<i>
								<InstagramFilled />
							</i>{' '}
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://twitter.com/copnowhq"
						>
							{' '}
							<i>
								<TwitterCircleFilled />
							</i>{' '}
						</a>
					</FooterIcons>
					<FooterCopyright>
						<p>Copyright &copy; {getYear()} Copnow</p>
					</FooterCopyright>
				</Container>
			</FooterWrapper>
		</Fragment>
	) : (
		<></>
	);
};

export default Footer;
