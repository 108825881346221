import { toast } from "react-toastify";

const successId = 'custom-success'
const errorId = 'custom-error'
const infoId = 'custom-info'
const warningId = 'custom-warning'
const defaultId = 'custom-default'

export default function Toast(message = '', props = {}, type = 'info') {
    if (typeof type !== 'string' || typeof message !== 'string' || typeof props !== 'object') {
        return;
    }
    switch(type.toLowerCase()) {
        case 'success':
            return toast.success(message, { ...props, toastId: successId });
        case 'error':
            return toast.error(message, { ...props, toastId: errorId });
        case 'info':
            return toast.info(message, { ...props, toastId: infoId });
        case 'warning':
            return toast.warn(message, { ...props, toastId: warningId });
        default: 
            return toast(message, { ...props, toastId: defaultId });
    }
}

export const Success = (message = 'Successful', options = {}) => {
    return toast.success(message, { ...options, toastId: successId })
}

export const Error = (error = null, options = {}, subMessage = '') => {
    let message = subMessage ? `${subMessage}. ` : subMessage
    if (error) {
        const errorData = error?.response?.data ?? {}
        message += errorData.message ? `${errorData.message}. ` : ''
        if (errorData.errors) {
            message += Object.values(errorData.errors).join('. ')
        }
        console.error(error)
    }
    return toast.warn(message, { ...options, toastId: warningId })
}