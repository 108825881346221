import { lazy, Suspense } from 'react';
import ActivitySpinner from '../shared/ActivitySpinner';

const TermsAndConditions = lazy(() =>
	import(
		/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditions/TermsAndConditions'
	)
);
const About = lazy(() =>
	import(/* webpackChunkName: "About" */ '../views/About/About')
);
const Blog = lazy(() =>
	import(/* webpackChunkName: "Blog" */ '../views/Blog/Blog')
);
const BlogDetail = lazy(() =>
	import(/* webpackChunkName: "BlogDetail" */ '../views/Blog/BlogDetail')
);
const Faqs = lazy(() =>
	import(/* webpackChunkName: "Faqs" */ '../views/Faqs/Faqs')
);
const PrivacyPolicy = lazy(() =>
	import(
		/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy/PrivacyPolicy'
	)
);
const ContactUs = lazy(() =>
	import(/* webpackChunkName: "Contact-Us" */ '../views/ContactUs/ContactUs')
);
const Ambassadors = lazy(() =>
	import(
		/* webpackChunkName: "Ambassadors" */ '../views/Ambassadors/Ambassadors'
	)
);

const spinnerStyle = {
	height: '100vh',
};

const CompanyRoutes = [
	{
		path: '/terms-and-conditions',
		name: 'TermsAndConditions',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<TermsAndConditions />
			</Suspense>
		),
	},
	{
		path: '/about',
		name: 'AboutUs',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<About />
			</Suspense>
		),
	},
	{
		path: '/blog',
		name: 'Blog',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<Blog />
			</Suspense>
		),
	},
	{
		path: '/blog/:id/:title',
		name: 'BlogDetail',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<BlogDetail />
			</Suspense>
		),
	},
	{
		path: '/faqs',
		name: 'FAQs',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<Faqs />
			</Suspense>
		),
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<PrivacyPolicy />
			</Suspense>
		),
	},
	{
		path: '/contact-us',
		name: 'ContacUs',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<ContactUs />
			</Suspense>
		),
	},
	{
		path: '/become-an-ambassador',
		name: 'Ambassadors',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<Ambassadors />
			</Suspense>
		),
	},
];

export default CompanyRoutes;
