import React, { Fragment } from 'react';
import { CartProvider } from './CartContext/CartContext';
import { CategoryProvider } from './CategoryContext/CategoryContext';
import { UserProvider } from './UserContext/UserContext';

const Context = ({ children }) => {

    return ( 
        <Fragment>
            <UserProvider>
                <CategoryProvider>
                    <CartProvider>
                        { children }
                    </CartProvider>
                </CategoryProvider>
            </UserProvider>
        </Fragment>
     );
}

export default Context;