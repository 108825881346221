import React from 'react';
import { WhyUseCopnowWrapper, WhyUseCopnowOuterDiv, WhyUseCopnowInnerDiv } from '../../views/Homepage/styles'
import why_use_copnow_logo1 from '../../assets/icons/why-use-copnow/why-use-copnow1.svg';
import why_use_copnow_logo2 from '../../assets/icons/why-use-copnow/why-use-copnow2.svg';
import why_use_copnow_logo3 from '../../assets/icons/why-use-copnow/why-use-copnow3.svg';
import why_use_copnow_logo4 from '../../assets/icons/why-use-copnow/why-use-copnow4.svg';

const whyUseCopnowItems = [
    {
        id: 1,
        image: why_use_copnow_logo1,
        subject: 'Secured Shopping',
        message: 'We take on certified sellers that sell top-of-the-line products.'
    },
    {
        id: 2,
        image: why_use_copnow_logo2,
        subject: 'Faster Delivery',
        message: 'Using geolocation technology provides shoppers with quick and reliable delivery, purchases can be delivered within 24 hours!'
    },
    {
        id: 3,
        image: why_use_copnow_logo3,
        subject: 'Swift Free Return',
        message: 'Easy refund based on our return policy.',
    },
    {
        id: 4,
        image: why_use_copnow_logo4,
        subject: 'Easy Navigation',
        message: 'We automatically source and display items you love at prices that fit your budget.'
    }
]

const WhyUseCopnow = () => {
    return ( 
        <WhyUseCopnowWrapper className='why-use-copnow'>
            <h4>Why use Copnow?</h4>
            <WhyUseCopnowOuterDiv>
                {
                    whyUseCopnowItems.map(item => (
                        <WhyUseCopnowInnerDiv key={item.id}>
                            <div>
                                <img src={item.image} alt="..."/>
                            </div>
                            <h6>{ item.subject }</h6>
                            <p>{ item.message }</p>
                        </WhyUseCopnowInnerDiv>
                    ))
                }
            </WhyUseCopnowOuterDiv>
        </WhyUseCopnowWrapper>
     );
}
 
export default WhyUseCopnow;
