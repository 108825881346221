import styled from 'styled-components';

const Container = ({ children, width, ...rest }) => {
	return (
		<StyledContainer width={width} {...rest}>
			{children}
		</StyledContainer>
	);
};

export default Container;

// STYLES
const StyledContainer = styled.div`
	width: 90%;
	margin: 0 auto;
	background: transparent;

	@media screen and (min-width: 1024px) {
		width: ${(props) => (props.width ? props.width : '80%')};
	}
`;
