import React, { useState, useEffect, useRef, createContext } from 'react';
import CategoryService from '../../services/CategoryService';

const CategoryContext = createContext('');
const { getCategories } = CategoryService();

const CategoryProvider = ({ children }) => {
	const [categories, setCategories] = useState([]);
	let mounted = useRef(true);
    let dataFetched = useRef(false)

	useEffect(() => {
		mounted.current = true;
        if (dataFetched.current) return;

		async function fetchData() {
			let categoryResponse = await getCategories({ sub: 1 });
			if (categoryResponse && mounted.current) {
				setCategories(categoryResponse);
			}
			dataFetched.current = false
		}

		fetchData();
		dataFetched.current = true

		return () => {
			setCategories([])
			mounted.current = false
		};
	}, []);

	return (
		<CategoryContext.Provider value={{ categories }}>
			{children}
		</CategoryContext.Provider>
	);
};

export { CategoryContext, CategoryProvider };
