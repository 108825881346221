import styled from 'styled-components';

export const StyledWrapper = styled.div`
	position: relative;
	margin: 2em 0;
	margin-bottom: 5em;
	padding: 3em 0;
	.banner-heading {
		text-align: center;
		margin-bottom: 5em;

		h2 {
			text-transform: capitalize;
			font-weight: 600;
		}

		p {
			font-size: 1.2em;
		}

		a {
			display: inline-block;
			border: 0.15em solid var(--black);
			padding: 1em;
			text-transform: capitalize;
			font-weight: 600;
			margin: 1em 0;
			border-radius: 8px;
			font-family: var(--font-stack);
			color: var(--black);
			font-size: 1.1em;
			width: 200px;
		}
	}

	.banner-grid {
		width: 80%;
		margin: 0 auto;

		.swiper {
			position: static;
		}

		.swiper-horizontal > .swiper-pagination-bullets {
			bottom: -34px;
		}

		.swiper-pagination-bullet-active {
			background: var(--blue);
		}

		.banner-grid-item {
			position: relative;
			height: 500px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.overlay-section {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background: rgba(80, 167, 239, 0.7);
				transform: translateY(100%);
				transition: transform 0.3s ease;

				a {
					color: var(--white);
					border: 0.1em solid var(--white);
					padding: 1em;
					border-radius: 8px;
					font-size: 1.1em;
					text-transform: capitalize;
				}
			}

			:hover .overlay-section {
				transform: translateY(0);
			}
		}
	}
`;
