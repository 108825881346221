import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ActivitySpinner from "../shared/ActivitySpinner";

const ResetPasswordForm = lazy(() =>
	import(/* webpackChunkName: "Authentication.ResetPasswordForm" */ '../components/auth/ResetPasswordForm')
);
const LoginForm = lazy(() =>
	import(/* webpackChunkName: "Authentication.LoginForm" */ '../components/auth/LoginForm')
);
const VerifyUserEmail = lazy(() =>
	import(/* webpackChunkName: "Authentication.VerifyUserEmail" */ '../components/auth/VerifyUserEmail')
);
const CustomRegistrationComponent = lazy(() =>
    import(/* webpackChunkName: "Authentication.Registration" */ '../components/auth/CustomRegistrationComponent')
)

const AuthRoutes = [
    {
        path: "/auth",
        exact: true,
        name: "RedirectToLogin",
        element: (
            <Navigate to={{ pathname: `auth/login` }} />
        )
    },
    {
        path: "signup",
        name: "SignUp",
        element: (
            <Suspense fallback={<ActivitySpinner />}>
				<CustomRegistrationComponent />
			</Suspense>
        )
    },
    {
        path: "reset-password",
        name: "ResetPassword",
        element: (
            <Suspense fallback={<ActivitySpinner />}>
				<ResetPasswordForm />
			</Suspense>
        )
    },
    {
        path: "login",
        name: "Login",
        element: (
            <Suspense fallback={<ActivitySpinner />}>
				<LoginForm />
			</Suspense>
        )
    },
    {
        path: "verify-email",
        name: "VerifyEmail",
        requiresAuth: true,
        element: VerifyUserEmail
    }
]

export default AuthRoutes;