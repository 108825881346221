import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ActivitySpinner from "../shared/ActivitySpinner";

const Overview = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Overview" */ '../components/vendorDashboard/Overview')
);
const ProductsComponent = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Products" */ '../components/vendorDashboard/ProductsComponent')
);
const MyProducts = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Products.MyProducts" */ '../components/vendorDashboard/MyProducts')
);
const VendorNotifications = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Notifications" */ '../components/vendorDashboard/VendorNotifications')
);
const WalletPage = lazy(() =>
	import(/* webpackChunkName: "User.Wallet" */ '../shared/WalletPage')
);
const VendorOrdersShipping = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.OrdersAndShipping" */ '../components/vendorDashboard/VendorOrdersShipping')
);
const VendorSettings = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Settings" */ '../components/vendorDashboard/VendorSettings')
);
const Statistics = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard.Statistics" */ '../components/vendorDashboard/Statistics')
);
const ProductListingForm = lazy(() => 
    import(/* webpackChunkName: "VendorDashboard.Products.ProductListingForm" */ '../shared/forms/ProductListingForm')
)

const spinnerStyle = {
	height: '100vh',
};

const VendorDashboardRoutes = [
    {
        path: "/vendor",
        exact: true,
        name: "RedirecToVendorOverview",
        element: (<Navigate to={{ pathname: `/vendor/dashboard` }} />)
    },
    {
        path: "dashboard",
        name: "VendorOverview",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<Overview />
			</Suspense>
        )
    },
    {
        path: "my-products",
        name: "VendorProducts",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<ProductsComponent />
			</Suspense>
        ),
        children: [
            {
                index: true,
                name: "VendorProductList",
                element: (
                    <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				        <MyProducts />
			        </Suspense>
                )
            },
            {
                path: "add",
                name: "VendorAddProduct",
                element: (
                    <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				        <ProductListingForm forDashboard={true}/>
			        </Suspense>
                )
            },
            {
                path: "edit/:id",
                name: "VendorEditProduct",
                element: (
                    <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				        <ProductListingForm forDashboard={true}/>
			        </Suspense>
                )
            }
        ]
    },
    {
        path: "notifications",
        name: "VendorNotifications",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<VendorNotifications />
			</Suspense>
        )
    },
    {
        path: "my-orders",
        name: "VendorOrders",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<VendorOrdersShipping />
			</Suspense>
        )
    },
    {
        path: "statistics",
        name: "VendorStatistics",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<Statistics />
			</Suspense>
        )
    },
    {
        path: "my-wallet",
        name: "VendorWallet",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<WalletPage />
			</Suspense>
        )
    },
    {
        path: "settings",
        name: "VendorSettings",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<VendorSettings />
			</Suspense>
        )
    }
]

export default VendorDashboardRoutes;