import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import CategoryBar from './CategoryBar';
import DownloadAppNotificationBar from './DownloadAppNotificationBar';

const pathsToDisableHeader = [
	'auth',
	'sell',
	'vendor',
	'user',
	'about',
	'admin',
	'blog',
	'become-an-ambassador',
];

const hideCategoryBar = (path) =>
	path.includes('/faqs') || path.includes('/contact-us');

const Header = () => {
	const { pathname } = useLocation();

	const toggleHeader = () => {
		const path = pathname.split('/').filter(Boolean)[0];

		return pathsToDisableHeader.includes(path) ? false : true;
	};

	return toggleHeader() ? (
		<Fragment>
			<DownloadAppNotificationBar />
			<Navbar />
			{hideCategoryBar(pathname) ? <></> : <CategoryBar />}
		</Fragment>
	) : (
		<></>
	);
};

export default Header;
