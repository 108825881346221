import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spin } from 'antd';

const styles = {
    Spinner: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        padding: 10px;
        width: 100%;
    `
}

const { Spinner } = styles

const ActivitySpinner = ({ size, style }) => {
    return ( 
        <Spinner style={style}>
            <Spin size={size}/>
        </Spinner>
     );
}

ActivitySpinner.propTypes = {
    size: PropTypes.string
}
 
export default ActivitySpinner;