import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext/CartContext';
import { UserContext } from '../contexts/UserContext/UserContext';
import { Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import liked from '../assets/icons/liked.svg';
import unliked from '../assets/icons/unliked.svg';
import Toast, { Error, Success } from './Alerts';
import { getPrice, renderProductImage } from './helpers';
import TokenService from '../services/TokenService';
import UserService from '../services/UserService';
import { Bag } from '../assets/icons/sidebar/sidebar-icons';

const { getToken } = TokenService();
const { saveItem, deleteSavedItem } = UserService();

const styles = {
	tooltipColor: "#50a7ef",
	addToCartButton: { borderRadius: '50%' },
	previewButton: { fontSize: '0.8em', padding: '0.5em' },
	modalImage: { margin: 'auto', display: 'block' },
	noSizes: { width: '100%', textAlign: 'center' },
	modalBody: { padding: '0 1em', width: '100%' }
}

const Container = styled.div`
	width: 300px;
	min-height: 380px;
	max-height: 400px;
	margin: auto;
	border-radius: 8px;
	transition: all 1s ease;
	display: flex;
	flex-direction: column;
	:hover {
		transform: scale(1.02);
		.hover-div {
			display: flex !important;
		}
		.overlay {
			transition: all 1s ease;
			opacity: 0.5;
		}
		.product-name {
			text-decoration: underline;
		}
	}
	.main-area {
		transition: all 1s ease;
		position: relative;
		.image-div {
			border-radius: 8px;
			img {
				border-radius: 8px;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.hover-div {
			transition: all 1s ease;
			display: none;
			position: absolute;
			z-index: 9998;
		}
		.favorite {
			top: 10px;
			right: 10px;
			z-index: 9999;
		}
		.favorite > span {
			cursor: pointer;
		}
		.action-buttons,
		.overlay {
			top: 0;
    		bottom: 0;
    		right: 0;
    		left: 0;
		}
		.overlay {
			transition: all 1s ease;
    		background: black;
			pointer-events: none;
			border-radius: 8px;
		}
		.action-buttons {
			align-items: center;
			justify-content: center;
			button {
				background: white;
				border: none;
				border-radius: 0.5em;
				margin: 0 0.5em;
				padding: 0.3em;
			}
		}
	}
	.description {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.3em;
		h6 {
			margin: 0.3em 0;
			font-weight: 700;
		}
		p {
			margin: 0.3em 0;
		}
		.name-div {
			display: flex;
			flex-direction: column;
		}
		.price {
			color: var(--blue-3);
		}

	}
`
const ModalWrapper = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;
		h6 {
			margin: 0.5em 0;
		}
		.colors-div {
			width: 100%;
			padding: 0.5em 0;
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid var(--grey-8);
			.colors {
				width: 100%;
				margin: 0.5em 0;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.color {
					height: 1.5em;
					width: 1.5em;
					display: inline-block;
					border-radius: 50%;
					margin: 0 0.3em;
					cursor: pointer;
				}
				.color:hover {
					border: 1px solid var(--blue-3) !important;
				}
			}
		}
		.sizes-div {
			width: 100%;
			padding: 0.5em 0;
			display: flex;
			flex-direction: column;
			.sizes {
				width: 100%;
				padding: 0.5em;
				display: flex;
				align-items: center;
				justify-content: space-between;
				p {
					margin: 0;
					font-size: 1.1em;
					font-weight: 500;
					text-transform: uppercase;
				}
				button {
					border: 1px solid var(--blue-3);
					border-radius: 0.5em;
					color: var(--blue-3);
					transition: all 0.3s ease;
					padding: 0.2em 0.5em;
					font-size: 0.8em;
					font-weight: 600;
				}
				button:hover {
				}
			}
		}
		.save-button-div {
			width: 100%;
			margin: 1em 0;
			button {
				background: var(--blue-3);
				border: 1px solid var(--blue-3);
				color: var(--white);
				border-radius: 0.5em;
				padding: 0.5em;
				width: 80%;
				transition: all 0.3s ease;
				margin: auto;
				display: block;
				font-weight: 600;
			}
			button:hover {
				background: none;
				color: var(--blue-3);
			}
		}
	`

const ProductCard = ({ product, ...rest }) => {
	const [showModal, toggleShowModal] = useState(false);
	const { setCart } = useContext(CartContext);
	const { user } = useContext(UserContext);
	const [favorite, setFavorite] = useState(!!(user?.saved_items && Array.isArray(user.saved_items) && user.saved_items.some(item => item.item_id === product.id)));
	const [productSize, setSize] = useState('');
	const [productColor, setColor] = useState('');
	const [mode, setMode] = useState('');
	let mounted = useRef(true);
	const { variations } = product?.variation ?? {};

	const addItem = () => {
		if ((product?.vendor_id === user?.vendor?.id) || (product?.vendor_store_id === user?.vendor_store?.id)) {
            Error(null, {}, 'You cannot add your own product!')
            return false
        }
		if (!productSize || !productColor) {
			Toast('Please select a valid color and size for product.', {}, 'warning');
			return false;
		}
		Toast('Item added to cart!', {
			autoClose: 1500,
		});
		setCart({
			type: 'add',
			product: {
				...product,
				product_size: productSize,
				product_color: productColor,
			},
		});
		return true;
	};

	const addToCart = () => {
		if (addItem()) {
			handleModalCancel();
		}
	};

	const handleFavorite = async (productId) => {
		if (!getToken()) {
			Toast('You need to login in order to add item to wishlist.');
			return;
		}

		let initialFavorite = favorite
		setFavorite(!favorite);
		let response;
		if (initialFavorite) {
			response = await deleteSavedItem(productId, { item_category: 'product' });
		} else {
			let data = {
				item_category: 'product',
				item_id: productId,
				user_id: user.id,
			};
			response = await saveItem(data);
		}

		if (response && mounted.current) {
			Success(`Item ${initialFavorite ? 'removed' : 'saved'} successfully`);
		}
	};

	const handleModalCancel = (e) => {
		if (e) {
			e.stopPropagation();
		}
		setSize('');
		setColor('');
		setMode('');
		toggleShowModal(false);
	};

	const productDescription = () => {
		let gender = (product.gender === "M") ? "Men" : (product.gender === "F") ? "Women" : "Unisex";
		let firstSubcategory = (product.product_sub_categories && Array.isArray(product.product_sub_categories)) ? product.product_sub_categories[0]?.sub_category_name : null
		return firstSubcategory ? `${firstSubcategory} for ${gender}` : `For ${gender}`;
	}

	const modalTitle = () => (mode === 'view') ? product.product_name : "Variation Select";

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false
		};
	}, []);

	return (
			<Container {...rest}>
				<div className='main-area'>
					<div className='image-div'>
						<img
							alt={product.product_name}
							src={renderProductImage(product.main_image, 'c_fill,h_300,w_300/q_auto:best/')}
						/>
					</div>
					<div className='hover-div overlay'></div>
					<div className='hover-div favorite'>
						<span
							role="img"
							onClick={(e) => {
								e.stopPropagation();
								handleFavorite(product.id);
							}}>
							{
								favorite ? (
									<img src={liked} alt="like" />
								) : (
									<img src={unliked} alt="not liked" />
								)
							}
						</span>
					</div>
					<div className='hover-div action-buttons'>
						<Tooltip title="Add to Cart" color={styles.tooltipColor} placement="left">
							<button style={styles.addToCartButton} onClick={e => {
								e.stopPropagation();
								setMode('add');
								toggleShowModal(true);
							}}>
								<Bag />
							</button>
						</Tooltip>
						<button style={styles.previewButton} onClick={e => {
							e.stopPropagation();
							setMode('view');
							toggleShowModal(true);
						}}>Preview</button>
					</div>
				</div>
				<div className='description'>
					<div className='name-div'>
						<Tooltip title="View Product" color={styles.tooltipColor} placement="right">
							<Link to={`/products/${product.id}/${product.product_name}`}>
								<h6 className='product-name'>
									{
										(product.product_name.length > 20) ? (product.product_name.slice(0, 20) + '...') : product.product_name
									}
								</h6>
							</Link>
						</Tooltip>
						<p>{ productDescription() }</p>
					</div>
					<h6 className="price">₦{ getPrice(product.product_price) }</h6>
				</div>
				{/* Modal design starts here */}
			<Modal
				visible={showModal}
				onCancel={handleModalCancel}
				centered
				title={modalTitle()}
				footer={null}
				bodyStyle={{ ...styles.modalBody,  height: (mode === 'view') ? '500px' : undefined }}
				maskClosable={false}
			>
				{(mode === 'view') ? (
					<img alt='selected product' src={renderProductImage(product.main_image, 'c_fit,h_480/q_auto:best/')} style={styles.modalImage}/>
				) : (<ModalWrapper
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="colors-div">
						<h6>Please select a color</h6>
						<div className="colors">
							{product?.colors && product.colors.length ? (
								product.colors.map((color, index) => (
									<span
										key={index}
										className="color"
										onClick={(e) => {
											e.stopPropagation();
											setColor(color);
											setSize('');
										}}
										style={{
											background: color,
											border: `1px solid ${color}`,
											transform:
												productColor === color ? 'scale(1.2)' : undefined,
										}}
									></span>
								))
							) : (
								<span>There are no available colors for this product!</span>
							)}
						</div>
						<small>Selected color: {productColor}</small>
					</div>
					<div className="sizes-div">
						<h6>Pick a size for selected color</h6>
						{productColor && variations ? (
							variations[productColor].map((variant, index) => (
								<div key={index} className="sizes">
									<p>{variant.size}</p>
									<button
										onClick={(e) => {
											e.stopPropagation();
											setSize(variant.size);
										}}
										style={{
											background:
												variant.size === productSize ? 'var(--blue-3)' : 'none',
											color:
												variant.size === productSize
													? 'var(--white)'
													: 'var(--blue-3)',
										}}
									>
										{variant.size === productSize ? 'Selected' : 'Select'}
									</button>
								</div>
							))
						) : (
							<span style={styles.noSizes}>
								No size to select!
							</span>
						)}
					</div>
					<div className="save-button-div">
						<button onClick={e => {
							e.stopPropagation();
							addToCart();
						}}>Add Product</button>
					</div>
				</ModalWrapper>)
			}
			</Modal>
		</Container>
	);
};

ProductCard.propTypes = {
	product: PropTypes.object.isRequired,
	rest: PropTypes.any,
};

export default ProductCard;
