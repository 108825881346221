import styled from "styled-components";

export const SettingsWrapper = styled.div`
	padding: 1em;

    .settings-tab-container {
        width: 100%;

	    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
	    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
		    overflow: auto;
	    }
	    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		    color: var(--blue-3) !important;
	    }
        .ant-tabs-ink-bar {
		    background: var(--blue-3) !important;
	    }

	    @media screen and (min-width: 1440px) {
		    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
		    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
			    overflow: hidden;
		    }
	    }
    }
`;

export const FormWrapper = styled.div`
    padding: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .section-header {
        padding: 0.5em 0;
		border-bottom: 1px solid var(--grey-4);
	    color: var(--grey-3);
        font-size: 1em;
        margin: 1em 0;
    }
    .button-div {
		margin: 2em 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		div {
			width: 50%;
		}
	}
	.header-description {
		p {
			margin: 0;
		}
		small {
			color: var(--blue-3);
		}
	}
	.sub-heading {
		font-size: 1em;
	}
	.tags-div {
		span > span {
			color: #fe1d34;
		}
	}
`

export const FormLine = styled.div`
    display: flex;
    justify-content: space-between;
    .width-contain {
        width: 45%;
    }
    @media screen and (max-width: 1020px) {
		flex-direction: column;
		align-items: center;
		.width-contain {
			width: 100%;
		}
    }
`

export const FormGroup = styled.div`
    margin: 1.25em 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		.custom-color-select {
			input {
				cursor: pointer;
				width: 25%;
			}
		}
		span {
			border-radius: 0.5em;
		}
		input,
		.ant-select-selector {
			border-radius: 0.5em !important;
			min-height: 3em !important;
		}
		.ant-input-number {
			border-radius: 0 0.5em 0.5em 0 !important;
			min-height: 3em !important;
			width: 100% !important;
		}
        .ant-input-suffix {
            color: var(--grey-3) !important;
        }
		.ant-input-number-group-addon {
			min-height: 3em !important;
			border-radius: 0.5em 0 0 0.5em !important;
		}
		.ant-select-selector {
			display: flex;
			align-items: center;
		}
		input::placeholder {
			font-size: 0.9em;
		}
		label {
			font-size: 1em;
			font-weight: 500;
			text-transform: capitalize;
			margin-bottom: 0.625em;
		}
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
		@media screen and (max-width: 500px) {
			.custom-color-select {
				input {
					width: 50%;
				}
			}
		}
        @media screen and (min-width: 1024px) {
            .form-group {
                width: 45%;
            }
        }
`

export const FileDiv = styled.div`
	padding: 1em;
	.file-uploads,
	.video-upload {
		display: flex;
		flex-direction: column;
		width: 100%;
		flex-wrap: wrap;
		.upload {
			width: 100%;
			height: 250px;
			display: flex;
			border: 1.5px dashed var(--grey-3);
			gap: 1em;
			margin: 1em 0;
			border-radius: 0.5em;
		}
	}
	@media screen and (min-width: 1024px) {
		.file-uploads,
		.video-uploads {
			flex-direction: row;
			justify-content: space-between;

			.upload {
				width: 25%;
				margin: 1em;
			}
		}
	}
`

export const DashboardWrapper = styled.div`
    min-height: 100vh;
    .sidebar-menu-btn {
		display: none;
	}
    @media screen and (max-width: 1023px) {
		.sidebar-menu-btn {
			border: none;
			background: var(--blue);
			border-radius: 50%;
			position: fixed;
			bottom: 3em;
			left: 2em;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 3.5em;
			width: 3.5em;
			color: var(--white);
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
				rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
			z-index: 995;

			button {
				background: none;
				border: none;
				cursor: pointer;
			}
		}
	}
    @media screen and (min-width: 1024px) {
		margin-left: 250px;
	}
`

export const DashboardSidebarWrapper = styled.div`
    display: flex;
	background-color: #fff;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	position: fixed;
	left: 0;
	height: 100vh;
	padding: 1em 0;
	overflow-y: auto;
	width: 250px;
	.link-items {
		display: flex;
		flex-direction: column;
		transition: all 0.5s ease;
		width: 100%;
	}
	.link-items > img {
		margin-bottom: 3em;
		cursor: pointer;
	}
	.link-items a,
	.logout-button {
		text-decoration: none;
		border-radius: 0.5em;
		padding: 0.5em;
		margin: 0.5em 0;
		font-size: 1.1em;
		font-weight: 600;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.logout-button {
		border: none;
		background: none;
		color: var(--grey-6);
		width: 100%;
	}
	.link-items a:not(.active):hover,
	.logout-button:hover {
		background: var(--grey-1);
	}

	.sidebar-close-btn {
		display: none;
	}

	@media screen and (max-width: 1023px) {
		position: fixed;
		top: 0;
		left: 0;
		transform: translateX(${(props) => (props.isClosed ? '-250px' : '0')});
		transition: all 0.3s ease;
		background: var(--white);
		z-index: 997;

		.sidebar-close-btn {
			display: block;
			position: absolute;
			top: 1.5em;
			right: 0;

			button {
				background: none;
				border: none;
				font-size: 1.2em;
				cursor: pointer;
			}
		}

		.link-items {
			margin-top: 2em;
		}
	}
`

export const TopbarWrapper = styled.div`
	padding: 0.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h5 {
		margin: 0;
		font-weight: 550;
	}
	.user-info {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		font-weight: 500;
		img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			margin: 0 0.5em;
		}
		p {
			margin: 0 0.5em;
		}
	}
	@media screen and (max-width: 500px) {
		h5 {
			font-size: 1.2em;
		}
		p {
			font-size: 0.9em;
		}
	}
`;

export const KycAddModalWrapper = styled.div`
	padding: 1em;
	.wrapper {
		padding: 2em 1em;
		transiton: all 1s ease-in-out;
		.action-button {
			width: 100% !important;
		}
	}
	.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
	.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
		background: var(--blue-3);
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: var(--blue-3);
	}
	.ant-steps-item-title {
		font-weight: 550;
		font-size: 13px;
	}
	@media screen and (max-width: 800px) {
		.steps-div {
			margin-top: 50px;
			.ant-steps-dot .ant-steps-item-content,
			.ant-steps-dot.ant-steps-small .ant-steps-item-content {
				width: 116px;
			}
		}
	}
	@media screen and (min-width: 1024px) {
		
	}
`

export const ActionButton = styled.button`
	color: white;
	padding: 0.8em;
	min-width: 200px;
	cursor: pointer;
	border: 1px solid var(--blue-3);
	border-radius: 0.5em;
	transition: 0.3s ease;
	text-transform: capitalize;
	white-space: nowrap;
	background: ${props => props.loading ? 'none' : 'var(--blue-3)'};
	font-weight: 600;
	:hover {
		background: none;
		color: var(--blue-3);
	}
	@media screen and (max-width: 500px) {
		margin: 0.5em 0;
		font-size: 0.9em;
	}
`

export const CodeInput = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	label {
		font-size: 0.9em;
		font-weight: bold;
		text-transform: capitalize;
		margin-bottom: 0.625em;
	}
	.code-inputs {
		display: flex;
		justify-content: start;
		align-items: center;
		input {
			border: none;
			background-image: none;
			background-color: transparent;
			color: var(--blue);
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			text-align: center;
			height: 60px;
			width: 40px;
			border-radius: 5px;
			margin: 0 4px;
			border: 1px solid #4f5b66;
			font-size: 38px;
		}
		input:hover {
			outline: none;
		}
		@media (max-width: 800px) {
			transform: scale(0.9);
		}
	}
`;

export const VariationControl = styled.div`
    padding: 0.5em;
	fieldset {
		padding: 1em;
		border: 1px solid var(--grey-4);
		border-radius: 0.5em;
	}
	.quantity-input {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.quantity {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0.5em;
			> span {
				width: 50%;
				border: 1px solid var(--grey-4);
				border-right: none;
				padding: 0.5em;
				text-align: center;
				border-radius: 0.5em 0 0 0.5em;
			}
			> input {
				width: 50%;
				padding: 0.5em;
				text-align: center;
				border-radius: 0 0.5em 0.5em 0;
			}
		}
	}
`

export const VariationDisplay = styled.div`
	padding: 0.5em;
	margin: 0.5em 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.color {
		display: flex;
		span {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			display: inline-block;
			margin: 0 0.2em;
		}
	}
	.variation-box {
		border: 1px solid var(--grey-4);
		border-radius: 0.5em;
		padding: 0.5em;
		margin: 0.5em;
		display: flex;
		flex-direction: column;
		> span {
			font-weight: 600;
		}
	}
	.delete {
		border: none;
		background: rgba(254, 29, 52, 0.1);
		color: #fe1d34;
		font-weight: 600;
		transition: all 0.5s ease-in-out;
		border-radius: 0.5em;
	}
	.delete:hover {
		transform: scale(1.03);
	}
`;