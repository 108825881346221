import React, { useState, useEffect, useRef } from 'react';
import SearchResults from './SearchResults/SearchResults';
import { IoSearchOutline } from 'react-icons/io5';
import ProductService from '../../services/ProductService';
import VendorService from '../../services/VendorService';
import { SearchDiv, StyledSearchInput } from './styles';
import { Spin } from 'antd';

const { searchForProduct } = ProductService();
const { searchForVendor } = VendorService();

const SearchBar = () => {
	const [query, setQuery] = useState('');
	const [queryOption, setQueryOption] = useState('products');
	const [results, setResults] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const [loading, setLoading] = useState(false);
	let mounted = useRef(true);

	const handleSearch = async () => {
		setLoading(true);
		if (queryOption === 'products') {
			const { results } = await searchForProduct(query);
			setResults(results);
			setShowResults(true);
		}

		if (queryOption === 'vendor') {
			const { results } = await searchForVendor(query);
			setResults(results);
			setShowResults(true);
		}
		setLoading(false);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			// Trigger the button element with a click
			document.getElementById('searchButton').click();
		}
	};

	useEffect(() => {
		mounted.current = true;

		let searchInput = document.getElementById('searchInput');

		searchInput.addEventListener('keypress', handleKeyPress);

		return () => {
			searchInput.removeEventListener('keypress', handleKeyPress);
			mounted.current = false;
		};
	}, []);

	return (
		<SearchDiv>
			<StyledSearchInput>
				<div className="search-input-div">
					<input
						id="searchInput"
						type="search"
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						placeholder="Search brands, products..."
					/>
				</div>
				<div className="search-select-div">
					<select
						value={queryOption}
						onChange={(e) => {
							setQueryOption(e.target.value);
							setResults([]);
						}}
					>
						<option value="products">products</option>
						<option value="vendor">brands</option>
					</select>
				</div>
				<div className="search-btn-div">
					<button
						onClick={handleSearch}
						disabled={!query || loading}
						id="searchButton"
						style={{ background: loading ? 'white' : undefined }}
					>
						{loading ? <Spin size="small" /> : <IoSearchOutline />}
					</button>
				</div>
			</StyledSearchInput>

			{showResults && (
				<SearchResults
					queryOption={queryOption}
					results={results}
					setShowResults={setShowResults}
				/>
			)}
		</SearchDiv>
	);
};

export default SearchBar;
