import React from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'animate.css/animate.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'aos/dist/aos.css';

import App from './App/App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

const history = createBrowserHistory();

ReactGA.initialize('G-0FTP5764GM');
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
	ReactGA.pageview(window.location.pathname + window.location.search);
});

root.render(
	<React.StrictMode>
		<BrowserRouter history={history}>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
