import React, { Fragment, useEffect, useRef } from 'react';
import { Routes as Switch, Route, useLocation,} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Toast from '../shared/Alerts';
import Guard from './Guard';
import AllRoutes from './AllRoutes';

const renderRoutes = (routes = []) => routes.map(route => {
	let routeProps = {
		key: route.name,
		path: route.path,
		exact: route.exact,
		index: route.index,
		element: route.requiresAuth ? (<Guard component={route.element}/>) : (route.element)
	}
	return (route.children && route.children.length) ? (
		<Route { ...routeProps }>
			{
				renderRoutes(route.children)
			}
		</Route>
	) : (
		<Route { ...routeProps }/>
	)
})

const Routes = () => {
	const location = useLocation();
	let mounted = useRef(true);

	const handleOffline = () => {
		Toast('You are currently offline', {}, 'error');
	};
	const handleOnline = () => {
		Toast('You are back online', {}, 'success');
	};

	useEffect(() => {
		mounted.current = true;

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
			mounted.current = false;
		};
	}, []);

	useEffect(() => {
		document.body.scrollTo(0, 0);
	}, [JSON.stringify(location)]);

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition classNames="fade" timeout={400} key={location.key}>
					<Switch>
						{
							renderRoutes(AllRoutes)
						}
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default Routes;
