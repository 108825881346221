import React, { Fragment, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CategoryContext } from '../../contexts/CategoryContext/CategoryContext';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CategorybarDiv } from './styles';

const styles = {
	menuItems: {
		padding: '10px',
		borderRadius: '8px',
	},
	icon: {
		fontSize: 'large',
	},
};

const pathsToDisableBar = ['user'];

const CategoryBar = () => {
	const { categories } = useContext(CategoryContext);
	const { pathname } = useLocation();

	const redirectToSubCategory = (event) => {
		// do something
	};

	const toggleCategoryBar = () => {
		const path = pathname.split('/').filter(Boolean)[0];

		return pathsToDisableBar.includes(path) ? false : true;
	};

	return toggleCategoryBar() ? (
		<Fragment>
			{categories.length ? (
				<CategorybarDiv>
					<nav>
						{categories.map((category) => (
							<Dropdown
								key={category.id}
								trigger={['hover', 'click']}
								overlay={
									<Menu
										onClick={redirectToSubCategory}
										style={styles.menuItems}
										items={category?.sub_categories.map((subCategory) => ({
											key: subCategory.id,
											label: (
												<Link
													className="text-capitalize"
													state={{
														category_id: category.id,
														sub_category_id: subCategory.id,
													}}
													to={`/category/${subCategory.sub_category_name.replaceAll(
														' ',
														'-'
													)}`}
												>
													{subCategory.sub_category_name}
												</Link>
											),
										}))}
									/>
								}
							>
								<Link
									to={`/category/${category.category_name.replaceAll(
										' ',
										'-'
									)}`}
									className="text-capitalize"
									state={{ category_id: category.id }}
								>
									{category.category_name} <DownOutlined />
								</Link>
							</Dropdown>
						))}
					</nav>
				</CategorybarDiv>
			) : (<></>)}
		</Fragment>
	) : (
		<></>
	);
};

export default CategoryBar;
