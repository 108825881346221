import { lazy, Suspense } from 'react';
import ActivitySpinner from '../shared/ActivitySpinner';

const ProductPage = lazy(() =>
	import(
		/* webpackChunkName: "ProductPage" */ '../views/ProductPage/ProductPage'
	)
);
const Checkout = lazy(() =>
	import(/* webpackChunkName: "Checkout" */ '../views/Checkout/Checkout')
);
const Cart = lazy(() =>
	import(/* webpackChunkName: "Cart" */ '../views/Cart/Cart')
);
const CategoryPage = lazy(() =>
	import(
		/* webpackChunkName: "CategoryPage" */ '../views/CategoryPage/CategoryPage'
	)
);
const VendorPage = lazy(() =>
	import(/* webpackChunkName: "VendorPage" */ '../views/VendorPage/VendorPage')
);

const spinnerStyle = {
	height: '100vh',
};

const SingleRoutes = [
	{
		path: '/products/:id/:name',
		name: 'ProductPage',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<ProductPage />
			</Suspense>
		),
	},
	{
		path: '/checkout',
		name: 'Checkout',
		requiresAuth: true,
		element: Checkout,
	},
	{
		path: '/category/:value',
		name: 'CategoryPage',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<CategoryPage />
			</Suspense>
		),
	},
	{
		path: '/vendors/:username?',
		name: 'VendorPage',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<VendorPage />
			</Suspense>
		),
	},
	{
		path: '/cart',
		name: 'Cart',
		element: (
			<Suspense
				fallback={<ActivitySpinner size="large" style={spinnerStyle} />}
			>
				<Cart />
			</Suspense>
		),
	},
];

export default SingleRoutes;
