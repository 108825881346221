import React, { useContext, useRef, useEffect } from 'react';
import { CategoryContext } from '../../contexts/CategoryContext/CategoryContext';
import {
	OurCategoriesWrapper,
	CategoriesContainer
} from '../../views/Homepage/styles';
import { FaArrowRight } from 'react-icons/fa';
import accessories_image from '../../assets/images/category-images/accessories.jpg';
import clothing_image from '../../assets/images/category-images/clothing.jpg';
import footwears_image from '../../assets/images/category-images/footwears.jpg';
import headwears_image from '../../assets/images/category-images/headwears.jpg';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { ActionButton } from '../../shared/sharedStyles'

const OurCategories = () => {
	const { categories } = useContext(CategoryContext);
	const navigate = useNavigate();
	let mounted = useRef(true);

	const redirect = (type = null) => {
		if (!type) return;

		const category = categories.find((category) => category.category_name.toLowerCase() === type.toLowerCase()) ?? {}
		const { category_name, id } = category;

		if (!category_name || !id) return;
		if (mounted.current) {
			navigate(`/category/${category_name.replaceAll(' ', '-')}`, {
				state: { category_id: id },
			});
		}
	};

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false
		};
	}, []);

	return !categories.length ? (<Skeleton active className='my-3 p-3'/>) : (
		<OurCategoriesWrapper>
			<h4>Categories</h4>

			<CategoriesContainer>
				<div className='double grid'>
					<div className='category-descriptions'>
						<h6>Exclusive collection 2023</h6>
						<h3>Be Exclusive</h3>
						<small>The best everyday option in a Super Saver range within a reasonable price. <br/>It is our responsibility to keep you 100 percent stylish. <br/>Be smart & trendy with us.</small>
						<ActionButton className='my-3' style={{ width: 'fit-content' }}>Explore</ActionButton>
					</div>
					<div className='category-image'>
						<img src={clothing_image} alt='clothing category'/>
						<button onClick={() => redirect('clothing')} className='category-nav-button'>Clothing <FaArrowRight/></button>
					</div>
				</div>

				<div className='triple grid'>
					<div className='category-image'>
						<img src={accessories_image} alt='accessories category'/>
						<button onClick={() => redirect('accessories')} type='button' className='category-nav-button'>Accessories <FaArrowRight/></button>
					</div>
					<div className='category-image'>
						<img src={headwears_image} alt='headwears category'/>
						<button onClick={() => redirect('headwears')} type='button' className='category-nav-button'>Headwears <FaArrowRight/></button>
					</div>
					<div className='category-image'>
						<img src={footwears_image} alt='footwears category'/>
						<button onClick={() => redirect('footwears')} type='button' className='category-nav-button'>Footwears <FaArrowRight/></button>
					</div>
				</div>
			</CategoriesContainer>
		</OurCategoriesWrapper>
	);
};

export default OurCategories;
