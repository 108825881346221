import Container from '../../shared/Container';
import { StyledWrapper } from './styles';

import phoneMockup from '../../assets/images/cop-mockup.png';
import googlePlayIcon from '../../assets/icons/playstore-img.svg';
import appStoreIcon from '../../assets/icons/appstore_img.svg';
import pattern from '../../assets/images/app-banner-pattern.png';

const DownloadAppBanner = () => {
	return (
		<StyledWrapper>
			<Container>
				<div className="app-banner-section">
					<div className="app-banner-section-left">
						<h2>download the app!</h2>
						<p>start shopping on the go!</p>
						<div className="download-app-btns">
							<a
								href="https://play.google.com/store/apps/details?id=com.product.copnow"
								target="_blank"
								rel="noreferrer"
							>
								<img src={googlePlayIcon} alt="google play icon" />
							</a>
							<a
								href="https://apps.apple.com/ng/app/copnow-look-good-everywhere/id6448952151"
								target="_blank"
								rel="noreferrer"
							>
								<img src={appStoreIcon} alt="app store icon" />
							</a>
						</div>
					</div>
					<div className="app-banner-section-right">
						<div className="phone-mockup-img">
							<img src={phoneMockup} alt="copnow mobile app" />
						</div>
					</div>
					<img
						src={pattern}
						className="app-banner-pattern"
						alt="copnow bg pattern"
					/>
				</div>
			</Container>
		</StyledWrapper>
	);
};

export default DownloadAppBanner;
