import Axios from "./Axios";
import { Error } from "../shared/Alerts"

function UserService() {
    const usersUrl = '/users'
    const usersOnboardUrl = usersUrl + "/onboard"
    const kycUrl = usersOnboardUrl + "/kyc"
    const bankUrl = kycUrl + "/bank"

    const getUserProfile = async () => {
        try {
            const { data } = await Axios.get(usersUrl + '/getProfile')
            return data
        } catch (error) {
            Error(error, {
                autoClose: 4000
            }, 'Unable to get user details.')
            console.error(error)
            return false;
        }
    }

    const updateUser = async (userId, params) => {
        try {
            const { data } = await Axios.post(usersUrl + `/update/${userId}`, params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const userChangePassword = async params => {
        const { current_password, new_password } = params
        if (!current_password || !new_password) {
            Error(null, {}, 'Kindly fill in the password inputs.')
            return false
        }
        try {
            const { data } = await Axios.post(usersUrl + '/change-password', params)
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const deleteUser = async userId => {
        try {
            const { data } = await Axios.delete(usersUrl + `/delete/${userId}`)
            return data;
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const getSavedItems = async (params = {}) => {
        try { 
            const { data } = await Axios.get(usersOnboardUrl + "/get-saved-products", { params })
            return data
        } catch (error) {
            Error(error, {}, "Error retrieving Saved items. Try again")
            return false;
        }
    }

    const saveItem = async params => {
        const itemCategories = ['product', 'store']
        const { item_category, item_id, user_id } = params
        if (!item_category || !item_id || !user_id || !itemCategories.includes(item_category)) {
            Error(null, {}, "Invalid parameters given.")
            return false
        }
        try {
            const { data } = await Axios.post(usersOnboardUrl + "/save-item", params)
            return data
        } catch (error) {
            Error(error, {}, "Error saving item.")
            return false;
        }
    }

    const deleteSavedItem = async (itemId, params = {}) => {
        const { item_category } = params
        if (!itemId || !item_category) {
            Error(null, {}, "Invalid paramters given.")
            return false
        }
        try {
            const { data } = await Axios.delete(usersOnboardUrl + `/delete-item/${itemId}`, { params })
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const userKycDetails = async () => {
        try {
            const { data } = await Axios.get(kycUrl + '/details')
            return data
        } catch (error) {
            Error(error, {}, "Error fetching KYC details.")
            return false
        }
    }

    const createUserKyc = async params => {
        try {
            const { data } = await Axios.post(kycUrl + '/create', params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
            Error(error, {}, 'Error creating KYC.')
            return false
        }
    }

    const kycVerifyPhone = async params => {
        const { verification_code } = params
        if (!verification_code) {
            Error(null, {}, 'Verification code is missing.')
            return false
        }
        try {
            const { data } = await Axios.post(kycUrl + '/verify-phone', params)
            return data
        } catch (error) {
            Error(error, {}, 'Error verifying OTP.')
            return false
        }
    }

    const resendKycVerificationCode = async () => {
        try {
            const { data } = await Axios.get(kycUrl + '/resend-otp')
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const getBankList = async () => {
        try {
            const { data } = await Axios.get(bankUrl + '/list')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching bank list')
            return false
        }
    }

    const validateUserBankAccount = async (params) => {
        const { account_no, bank_code } = params
        if (!account_no || !bank_code) {
            Error(null, {}, 'Incomplete details supplied')
            return false
        }
        try {
            const { data } = await Axios.post(bankUrl + '/validate-account', params)
            return data
        } catch (error) {
            Error(error, {}, 'Error validating account number.')
            return false
        }
    }

    const getUserTransactions = async () => {
        try {
            const { data } = await Axios.get('/transactions')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching transactions')
            return false
        }
    }

    const getUserDashboardStats = async () => {
        try {
            const { data } = await Axios.get(usersOnboardUrl + '/dashboard-stats')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching stats')
            return false
        }
    }

    return {
        getUserProfile, updateUser, deleteUser,
        getSavedItems, saveItem, deleteSavedItem,
        userChangePassword, userKycDetails,
        createUserKyc, kycVerifyPhone, getBankList,
        validateUserBankAccount, getUserTransactions,
        resendKycVerificationCode, getUserDashboardStats
    }
}

export default UserService 