import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ActivitySpinner from "../shared/ActivitySpinner";

const SellOnCopnowInfo = lazy(() =>
	import(/* webpackChunkName: "SellOnCopnow.Info" */ '../components/sellOnCopnow/SellOnCopnowInfo')
);
const SellOnCopnowForm = lazy(() =>
	import(/* webpackChunkName: "SellOnCopnow.Register" */ '../components/sellOnCopnow/SellOnCopnowForm')
);

const SellOnCopnowRoutes = [
    {
        path: "/sell",
        exact: true,
        name: "RedirectToSellLanding",
        element: (<Navigate to={{ pathname: '/sell/landing' }} />)
    },
    {
        path: "landing",
        name: "SellOnCopnowLanding",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" />}>
				<SellOnCopnowInfo />
			</Suspense>
        )
    },
    {
        path: "register",
        name: "SellOnCopnowRegister",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" />}>
				<SellOnCopnowForm />
			</Suspense>
        )
    }
]

export default SellOnCopnowRoutes;