import styled from 'styled-components';

export const FooterWrapper = styled.div`
	width: 100%;
	justify-content: center;
	background: var(--dark-blue-4);
	color: #fff;
	padding: 20px 20px 20px 20px;

	p {
		color: #fff;
	}
`;
export const FooterContainer = styled.div`
	padding-top: 10px;

	img {
		width: 40%;
		object-fit: contain;

		@media screen and (min-width: 1024px) {
			width: 60%;
		}
	}

	h5 {
		color: var(--white);
		font-size: 1.2em;
		font-weight: bold;
		padding: 7px;
		padding-left: 0;
		text-transform: uppercase;
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			margin-bottom: 0.8em;
			a {
				font-size: 1em;
				color: var(--white);
				text-transform: capitalize;
				text-align: left;
			}
		}
	}
`;
export const FooterCount = styled.div`
	margin-top: 20px;
	display: flex;
	text-align: center;

	p {
		font-size: 1rem;
		padding: 0.5rem;
		text-align: left;
	}
`;
export const FooterIcons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	i {
		padding: 0px 10px;
		font-size: 20px;
		color: #fff;
	}
`;
export const FooterCopyright = styled.div`
	margin-top: 5px;
	text-align: center;
`;

export const FooterGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(226px, 1fr));
	gap: 0.5em;
	margin: 1.2em 0;
`;

export const FooterGridItem = styled.div``;
