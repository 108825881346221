import Axios from "./Axios";
import { Error } from "../shared/Alerts";

function LandingPageService() {
    const url = '/guest/products'

    const getTopVendors = async params => {
        try {
            const { data } = await Axios.get(url + '/get-top-vendors', { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Top Vendors.")
            return false
        }
    }

    const getLocationItems = async (with_location = 1) => {
        let params = { with_location }
        try {
            const { data } = await Axios.get(url + '/get-user-location-items', { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Location Items.")
            return false
        }
    }

    const getTopItems = async () => {
        try {
            const { data } = await Axios.get(url + '/get-top-items')
            return data
        } catch (error) {
            Error(error, {}, "Error fetching top items.")
            return false
        }
    }

    const getNewItems = async () => {
        try {
            const { data } = await Axios.get(url + '/get-new-items')
            return data
        } catch (error) {
            Error(error, {}, "Error fetching new items.")
            return false
        }
    }

    const getRecentActivity = async () => {
        try {
            const { data } = await Axios.get(url + '/get-recent-activity')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching recent activity.')
            return false
        }
    }

    return { 
        getTopVendors, getLocationItems,
        getTopItems, getRecentActivity,
        getNewItems
    }
}

export default LandingPageService