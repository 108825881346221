import React, { createContext, useEffect, useReducer, useRef } from "react";
import TokenService from '../../services/TokenService';
import UserService from '../../services/UserService';

const userReducer = (state, user = {}) => {
    if (!Object.keys(user).length) {
        return {}
    }
    return {
        ...state,
        ...user
    }
}

const UserContext = createContext('')
const { getUserProfile } = UserService()
const { getToken } = TokenService()

const UserProvider = ({ children }) => {
    const [user, setUser] = useReducer(userReducer, {})
    let mounted = useRef(true)
    let dataFetched = useRef(false)
    const token = getToken()
    
    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        if (token) {
            async function fetchData() {
                let userResponse = await getUserProfile()
                if (userResponse && mounted.current) {
                    let { user_details } = userResponse
                    setUser(user_details)
                }
                dataFetched.current = false
            }

            fetchData()
            dataFetched.current = true
        }
        
        
        return () => {
            setUser({})
            mounted.current = false;
        }
    }, [token])

    return (
        <UserContext.Provider value={{ user, setUser }}>
            { children }
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }