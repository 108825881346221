import Axios from './Axios';
import { Error } from '../shared/Alerts';

function ProductService() {
	const url = '/products';

    const getProducts = async params => {
        try {
            const { data } = await Axios.get(url + '/list', { params })
            return data
        } catch (error) {
            Error(error, {}, "Error fetching products.")
            return false
        }
    }

    const createProduct = async params => {
        try {
            const { data } = await Axios.post(url + '/create', params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
           Error(error)
            return false
        }
    }

    const getSingleProduct = async productId => {
        if (!productId) {
            Error(null, {}, "Incomplete data supplied")
            return false
        }
        try {
            const { data } = await Axios.get(url + `/read/${productId}`)
            return data
        } catch (error) {
            Error(error, {}, "Error fetching Product detail.")
            return false
        }
    }

    const updateProduct = async (productId, params) => {
        if (!productId) {
            Error(null, {}, "Incomplete data supplied")
            return false
        }
        try {
            const { data } = await Axios.post(url + `/update/${productId}`, params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const deleteProduct = async productId => {
        try {
            const { data } = await Axios.delete(url + `/delete/${productId}`)
            return data
        } catch (error) {
            Error(error)
            return false
        }
    }

    const getProductConditions = async () => {
        try {
            const { data } = await Axios.get(url + '/product-conditions/list')
            return data
        } catch (error) {
            Error(error, {}, 'Error fetching product conditions')
            return false
        }
    }

    const publishProduct = async (productId, params) => {
        const { status } = params
        if (!status && (status !== 0)) {
            Error(null, {}, "Invalid params given.")
            return false
        }
        try {
            const { data } = await Axios.put(url + `/publish/${productId}`, params)
            return data
        } catch (error) {
            Error(error, {}, 'Error publishing your product. Please refresh the page and try again or contact Support.')
            return false
        }
    }

	const searchForProduct = async (query) => {
		try {
			const { data } = await Axios.get(`/search/products?search=${query}`);
			return data;
		} catch (error) {
			console.log(error);
			Error('something went wrong');
			return false;
		}
	};

	return {
		getProducts,
		createProduct,
		getSingleProduct,
		updateProduct,
		deleteProduct,
		getProductConditions,
		publishProduct,
		searchForProduct,
	};
}

export default ProductService;
