import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { VendorSearchResultCardWrapper } from '../../styles';

import sample from '../../../../assets/images/new_hero_imgs/1.jpg';

const VendorSearchResultCard = ({ vendorDetails }) => {
	const { store_name, business_logo, store_username } = vendorDetails;

	return (
		<VendorSearchResultCardWrapper>
			<div className="search-vendor-img">
				<img src={business_logo || sample} alt="" />
			</div>
			<div className="search-vendor-description">
				<h3>{store_name || "debbie's fabrics"}</h3>
				<div className="vendor-location">
					<span>
						<HiOutlineLocationMarker />
					</span>
					<span>lagos</span>
				</div>
			</div>
			<div className="search-vendor-view-store-btn">
				<Link to={`/vendors/${store_username}?`}>view store</Link>
			</div>
		</VendorSearchResultCardWrapper>
	);
};

export default VendorSearchResultCard;

// PROPTYPES
VendorSearchResultCard.propTypes = {
	productDetails: PropTypes.object,
};
