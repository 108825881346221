import React, { createContext, useReducer } from 'react';

const CartContext = createContext('')

const initialProduct = {
    quantity: 1
}

const cartStorageName = 'copnow_cart'

const hasCart = () => localStorage.hasOwnProperty(cartStorageName)

const deleteCart = () => localStorage.removeItem(cartStorageName)

const getCart = () => {
    if (!hasCart()) {
        return []
    }
    const jsonStringifiedCart = localStorage.getItem(cartStorageName)

    return JSON.parse(jsonStringifiedCart)
}

const saveCart = (cart = []) => {
    const jsonStringifiedCart = JSON.stringify(cart)
    localStorage.setItem(cartStorageName, jsonStringifiedCart)

    return true
}

const cartReducer = (state, action) => {
    let tempCart;
    switch(action.type) {
        case 'add': {
            if (state.some(item => ((item.id === action.product.id) && (item.product_color === action.product.product_color)))) {
                tempCart = state.map(item => {
                    if ((item.id === action.product.id) && (item.product_size === action.product.product_size) && (item.product_color === action.product.product_color)) {
                        return {
                            ...action.product,
                            quantity: item.quantity + 1
                        }
                    } else if ((item.id === action.product.id) && (item.product_size !== action.product.product_size) && (item.product_color === action.product.product_color)) {
                        return { ...action.product }
                    }
                    return { ...item }
                })
                saveCart(tempCart)
                return tempCart
            }

            tempCart = [...state, { ...initialProduct, ...action.product }];
            saveCart(tempCart)
            return tempCart
        }
        case 'remove': {
            const productIndex = state.findIndex(item => ((item.id === action.product.id) && (item.product_size === action.product.product_size) && (item.product_color === action.product.product_color)))
            if (productIndex < 0) {
                tempCart = state;
                saveCart(tempCart)
                return tempCart
            }
            const updatedState = [...state]
            updatedState.splice(productIndex, 1)
            saveCart(updatedState)
            return updatedState;
        }
        case 'decrement': {
            tempCart = state.map(item => {
                if ((item.id === action.product.id) && (item.product_size === action.product.product_size) && (item.product_color === action.product.product_color)) {
                    return {
                        ...action.product,
                        quantity: action.product.quantity - 1
                    }
                }
                return { ...item }
            });
            saveCart(tempCart)
            return tempCart
        }
        case 'increment': {
            tempCart = state.map(item => {
                if ((item.id === action.product.id) && (item.product_size === action.product.product_size) && (item.product_color === action.product.product_color)) {
                    return {
                        ...action.product,
                        quantity: action.product.quantity + 1
                    }
                }
                return { ...item }
            });
            saveCart(tempCart)
            return tempCart
        }
        case 'empty': {
            tempCart = []
            saveCart(tempCart)
            return tempCart
        }
        case 'intersect': {
            tempCart = state.filter(item => item.vendor_id !== action.vendorId)
            saveCart(tempCart)
            return tempCart;
        }
        default: {
            tempCart = state;
            saveCart(tempCart)
            return tempCart
        }
    }
}

const CartProvider = ({ children }) => {
    const [cart, setCart] = useReducer(cartReducer, getCart())


    const getTotals = () => {
        let cartTotal =  cart.reduce((totalCost, product) => totalCost + (product.quantity * product.product_price), 0)
        let tax = 0
        let total = cartTotal + tax

        return {
            subTotal: cartTotal,
            tax,
            total
        }
    }

    const cartMethods = () => {

        const removeProductFromCart = product => {
            setCart({
                type: 'remove',
                product
            })
        }

        const decrementProduct = product => {
            if (product.quantity === 1) {
                return;
            }
            setCart({
                type: 'decrement',
                product
            })
        }

        const incrementProduct = product => {
            setCart({
                type: 'increment',
                product
            })
        }

        const clearUserItems = vendorId => {
            setCart({
                type: 'intersect',
                vendorId
            })
        }

        return { removeProductFromCart, decrementProduct, incrementProduct, clearUserItems }
    }

    return (
        <CartContext.Provider value={{ cart, setCart, getTotals, cartMethods }}>
            { children }
        </CartContext.Provider>
    )
}

export { CartContext, CartProvider, deleteCart }