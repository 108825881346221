import { useState } from 'react';
import PropTypes from 'prop-types';
import ProductSearchResultCard from './ProductSearchResultCard';
import { ProductSearchResultsWrapper } from '../../styles';

const resultsPerRow = 4;

const ProductSearchResults = ({ results }) => {
	const [next, setNext] = useState(resultsPerRow);

	const handleMoreResults = () => {
		setNext(next + resultsPerRow);
	};

	return (
		<ProductSearchResultsWrapper>
			{results.length > 0 && (
				<p className="showing-text">
					showing: <span>{results.length}</span> result(s) found
				</p>
			)}
			<div className="product-search-results-grid">
				{results.length > 0 ? (
					results
						?.slice(0, next)
						?.map((searchResult) => (
							<ProductSearchResultCard
								key={searchResult.id}
								productDetails={searchResult}
							/>
						))
				) : (
					<p>no result found</p>
				)}
			</div>
			{next < results?.length && (
				<div className="show-all-results">
					<button onClick={handleMoreResults}>see more</button>
				</div>
			)}
		</ProductSearchResultsWrapper>
	);
};

export default ProductSearchResults;

// PROPTYPES
ProductSearchResults.propTypes = {
	results: PropTypes.array,
};
