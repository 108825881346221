import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	Suspense,
	lazy,
} from 'react';
import ActivitySpinner from '../../shared/ActivitySpinner';
import { HomepageWrapper } from './styles';
import WhyUseCopnow from '../../components/homepage/WhyUseCopnow';
import TopItems from '../../shared/TopItems';
import HeroSection from '../../components/homepage/HeroSection';
import OurCategories from '../../components/homepage/OurCategories';
import { UserContext } from '../../contexts/UserContext/UserContext';
import VendorBanner from '../../shared/VendorBanner/VendorBanner';
import NewItems from '../../components/homepage/NewItems';
import DownloadAppBanner from '../../components/DownloadAppBanner/DownloadAppBanner';

const LocationItems = lazy(() =>
	import(
		/* webpackChunkName: "Homepage.LocationItems" */ '../../components/homepage/LocationItems'
	)
);
const TopVendors = lazy(() =>
	import(
		/* webpackChunkName: "Homepage.TopVendors" */ '../../components/homepage/TopVendors'
	)
);
const SelectedItems = lazy(() =>
	import(
		/* webpackChunkName: "Homepage.SelectedItems" */ '../../components/homepage/SelectedItems'
	)
);

const Homepage = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const { user } = useContext(UserContext);
	let mounted = useRef(true);

	useEffect(() => {
		mounted.current = true;

		if (user.id && !authenticated) {
			setAuthenticated(true);
		}

		return () => {
			setAuthenticated(false);
			mounted.current = false;
		};
	}, [JSON.stringify(user)]);

	return (
		<HomepageWrapper>
			<HeroSection />
			<OurCategories />

			<TopItems />

			<NewItems />

			<DownloadAppBanner />

			{authenticated ? (
				<Suspense fallback={<ActivitySpinner size="small" />}>
					<LocationItems />

					<SelectedItems />
				</Suspense>
			) : (
				<></>
			)}

			<VendorBanner />

			{authenticated ? (
				<Suspense fallback={<ActivitySpinner size="small" />}>
					<TopVendors />
				</Suspense>
			) : (
				<></>
			)}

			<WhyUseCopnow />
		</HomepageWrapper>
	);
};

export default Homepage;
