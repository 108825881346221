import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../shared/Slider';
import ProductCard from '../../shared/ProductCard';
import { SelectedItemsWrapper as NewItemsWrapper } from '../../views/Homepage/styles';
import LandingPageService from '../../services/LandingPageService';
import { Skeleton } from 'antd';

const { getNewItems } = LandingPageService()

const NewItems = ({ onSlideChange }) => {
	const [loading, setLoading] = useState(true)
    const [newItems, setNewItems] = useState([])
	let mounted = useRef(true)
    let dataFetched = useRef(false)

    const convertItems = (items = []) => {
		return items.map((item, index) => (
			<ProductCard product={item} key={index} />
		));
	}

    useEffect(() => {
		mounted.current = true
        if (dataFetched.current) return;

        async function fetchItems() {
            const newItemsResponse = await getNewItems()

            if (newItemsResponse.data && mounted.current) {
                const { data } = newItemsResponse
                setNewItems(convertItems(data))
            }
            dataFetched.current = false
            setLoading(false)
        }

        fetchItems()
        dataFetched.current = true

        return () => {
            setNewItems([])
            setLoading(true)
            mounted.current = false
        }
    }, [])

    return loading ? (<Skeleton active className='my-3 p-3'/>) : newItems.length ? (
        <NewItemsWrapper>
            <h4 style={{ textAlign: 'center' }}>New Products</h4>

            <Slider
				itemCount={4}
				items={newItems}
				onSlideChange={onSlideChange}
			/>
        </NewItemsWrapper>
    ) : (<></>);
}

NewItems.propTypes = {
	onSlideChange: PropTypes.func,
};
 
export default NewItems;