import { lazy, Suspense } from "react";
import ActivitySpinner from "../shared/ActivitySpinner";
import Homepage from '../views/Homepage/Homepage';
// import AdminDashboardRoutes from "./AdminDashboard";
import AuthRoutes from "./Auth";
import CompanyRoutes from "./CompanyRoutes";
import SellOnCopnowRoutes from "./SellOnCopnow";
import SingleRoutes from "./SingleRoutes";
import UserDashboardRoutes from "./UserDashboard";
import VendorDashboardRoutes from "./VendorDashboard";

const Authentication = lazy(() =>
	import(/* webpackChunkName: "Authentication" */ '../views/Authentication/Authentication')
);
const SellOnCopnowLanding = lazy(() =>
	import(/* webpackChunkName: "SellOnCopnow" */ '../views/SellOnCopnow/SellOnCopnowLanding')
);
const UserDetails = lazy(() =>
	import(/* webpackChunkName: "UserDetails" */ '../views/UserDetails/UserDetails')
);
const VendorDashboard = lazy(() =>
	import(/* webpackChunkName: "VendorDashboard" */ '../views/VendorDashboard/VendorDashboard')
);
// const AdminDashboard = lazy(() =>
// 	import(/* webpackChunkName: "AdminDashboard" */ '../views/AdminDashboard/AdminDashboard')
// );
const PageNotFound = lazy(() =>
	import(/* webpackChunkName: "404.PageNotFound" */ '../views/PageNotFound/PageNotFound')
);

const spinnerStyle = {
	height: '100vh',
};

const AllRoutes = [
    {
        path: "/",
        name: "HomePage",
        element: (
            <Homepage />
        )
    },
    ...CompanyRoutes,
    {
        path: "/auth",
        name: 'Authentication',
        element: (
            <Suspense fallback={<ActivitySpinner style={spinnerStyle} size="large" />}>
				<Authentication />
			</Suspense>
        ),
        children: [ ...AuthRoutes ]
    },
    ...SingleRoutes,
    {
        path: "/sell",
        name: "SellOnCopnow",
        requiresAuth: true,
        element: SellOnCopnowLanding,
        children: [ ...SellOnCopnowRoutes ]
    },
    {
        path: "/user",
        name: "UserDashboard",
        requiresAuth: true,
        element: UserDetails,
        children: [ ...UserDashboardRoutes ]
    },
    {
        path: "/vendor",
        name: "VendorDashboard",
        requiresAuth: true,
        element: VendorDashboard,
        children: [ ...VendorDashboardRoutes ]
    },
    // {
    //     path: "/admin",
    //     name: "AdminDashboard",
    //     requiresAuth: true,
    //     element: AdminDashboard,
    //     children: [ ...AdminDashboardRoutes ]
    // },
    {
        path: "*",
        name: "PageNotFound",
        element: (
            <Suspense fallback={<ActivitySpinner style={spinnerStyle} size="large" />}>
				<PageNotFound />
			</Suspense>
        )
    }
]

export default AllRoutes;