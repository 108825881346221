import React, {
	Fragment,
	useEffect,
	useContext,
	useRef,
	useState,
	Suspense,
} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext/UserContext';
import { CartContext } from '../../contexts/CartContext/CartContext';
import logo from '../../assets/images/copnow-logo.png';
import { NavbarDiv } from './styles';
import SearchBar from './SearchBar';
import ActivitySpinner from '../../shared/ActivitySpinner';
import { ShoppingCartOutlined, LoginOutlined } from '@ant-design/icons';
import { UserIcon } from '../../assets/icons/UserIcon';
import { Badge } from 'antd';


const Navbar = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const { user } = useContext(UserContext);
	const { cart } = useContext(CartContext);
	let mounted = useRef(true);

	useEffect(() => {
		mounted.current = true;

		if (user.id) {
			setAuthenticated(true);
		} else {
			setAuthenticated(false);
		}

		return () => {
			setAuthenticated(false)
			mounted.current = false;
		};
	}, [JSON.stringify(user)]);

	return (
		<NavbarDiv>
			<div className="logo-section">
				<Link to="/">
					<img src={logo} alt="copnow header logo" />
				</Link>
			</div>
			<SearchBar />
			<nav>
				<button className="navbar-button">
					{((user.user_type === 'V') && user.vendor_store) ? (
						<Link to="/vendor/dashboard">Store</Link>
					) : (
						<Link to="/sell">Sell</Link>
					)}
				</button>
				<span className="divider"></span>
				{!authenticated && (
					<Suspense fallback={<ActivitySpinner size="small" />}>
						<Fragment>
							<button className="navbar-button">
								<Link to="/auth/login">
									<LoginOutlined className="navbar-button-icon" />
									Sign In
								</Link>
							</button>
							<span className="divider"></span>
						</Fragment>
					</Suspense>
				)}
				{authenticated && (
					<Suspense fallback={<ActivitySpinner size="small" />}>
						<Fragment>
							<button aria-label="user menu" className="navbar-button">
								<Link to="/user/my-account">
									<UserIcon className="navbar-button-icon" />
									My Account
								</Link>
							</button>
							<span className="divider"></span>
						</Fragment>
					</Suspense>
				)}
				<Badge
					count={cart.length}
					showZero
					offset={[-15, 7]}
					size="small"
					color={'var(--blue-3)'}
					style={{ fontSize: '10px' }}
					className="cart-badge"
				>
					<button className="navbar-button">
						<Link to="/cart">
							<ShoppingCartOutlined className="navbar-button-icon" />
							Cart
						</Link>
					</button>
				</Badge>
			</nav>
		</NavbarDiv>
	);
};

export default Navbar;
