import React from 'react';

export const Bag = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0134 21.5003H8.66555C5.59919 21.5003 3.24679 20.3927 3.91498 15.9351L4.69301 9.89387C5.10491 7.66962 6.52367 6.81836 7.76852 6.81836H17.947C19.2102 6.81836 20.5466 7.73369 21.0225 9.89387L21.8006 15.9351C22.3681 19.8893 20.0797 21.5003 17.0134 21.5003Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.1512 6.59873C17.1512 4.21265 15.2169 2.27836 12.8309 2.27836V2.27836C11.6819 2.27349 10.5782 2.72652 9.76406 3.53727C8.94987 4.34803 8.49218 5.44971 8.49219 6.59873V6.59873"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.797 11.1022H15.7512"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.96492 11.1022H9.91916"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Buy = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.125 10.7949H16.898"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.15338 20.2021C7.45438 20.2021 7.69738 20.4461 7.69738 20.7461C7.69738 21.0471 7.45438 21.2911 7.15338 21.2911C6.85238 21.2911 6.60938 21.0471 6.60938 20.7461C6.60938 20.4461 6.85238 20.2021 7.15338 20.2021Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.4346 20.2021C18.7356 20.2021 18.9796 20.4461 18.9796 20.7461C18.9796 21.0471 18.7356 21.2911 18.4346 21.2911C18.1336 21.2911 17.8906 21.0471 17.8906 20.7461C17.8906 20.4461 18.1336 20.2021 18.4346 20.2021Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Chart = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M7.37072 10.2021V17.0623"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0387 6.91895V17.0617"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.6285 13.8271V17.0622"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Chat = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.0714 19.0699C16.0152 22.1263 11.4898 22.7867 7.78642 21.074C7.23971 20.8539 6.79148 20.676 6.36537 20.676C5.17849 20.683 3.70117 21.8339 2.93336 21.067C2.16555 20.2991 3.31726 18.8206 3.31726 17.6266C3.31726 17.2004 3.14642 16.7602 2.92632 16.2124C1.21283 12.5096 1.87411 7.98269 4.93026 4.92721C8.8316 1.02443 15.17 1.02443 19.0714 4.9262C22.9797 8.83501 22.9727 15.1681 19.0714 19.0699Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.9408 12.4131H15.9498"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9291 12.4131H11.9381"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.92128 12.4131H7.93028"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Logout = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M15.0174 7.38948V6.45648C15.0174 4.42148 13.3674 2.77148 11.3324 2.77148H6.45744C4.42344 2.77148 2.77344 4.42148 2.77344 6.45648V17.5865C2.77344 19.6215 4.42344 21.2715 6.45744 21.2715H11.3424C13.3714 21.2715 15.0174 19.6265 15.0174 17.5975V16.6545"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.8105 12.0215H9.76953"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.8828 9.10547L21.8108 12.0205L18.8828 14.9365"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Notifications = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.3889 20.8574C13.0247 22.3721 10.8967 22.3901 9.51953 20.8574"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Settings = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.8054 7.62386L20.183 6.54377C19.6564 5.62985 18.4895 5.31456 17.5743 5.83896V5.83896C17.1387 6.09559 16.6189 6.1684 16.1295 6.04133C15.6401 5.91427 15.2214 5.59776 14.9656 5.16162C14.8011 4.88439 14.7127 4.56864 14.7093 4.24628V4.24628C14.7242 3.72947 14.5292 3.22865 14.1688 2.85791C13.8084 2.48718 13.3133 2.27811 12.7963 2.27832H11.5423C11.0357 2.27831 10.5501 2.48016 10.1928 2.83919C9.83547 3.19822 9.63595 3.68484 9.63839 4.19136V4.19136C9.62338 5.23717 8.77126 6.07706 7.72535 6.07695C7.40299 6.0736 7.08724 5.98519 6.81001 5.82065V5.82065C5.89484 5.29626 4.72789 5.61154 4.20132 6.52546L3.53313 7.62386C3.00719 8.53664 3.31818 9.70285 4.22878 10.2326V10.2326C4.82068 10.5743 5.18531 11.2058 5.18531 11.8893C5.18531 12.5728 4.82068 13.2043 4.22878 13.5461V13.5461C3.31934 14.0722 3.00801 15.2356 3.53313 16.1456V16.1456L4.1647 17.2348C4.41143 17.68 4.82538 18.0085 5.31497 18.1477C5.80456 18.2868 6.32942 18.2251 6.7734 17.9763V17.9763C7.20986 17.7216 7.72997 17.6518 8.21812 17.7824C8.70627 17.9131 9.12201 18.2333 9.37294 18.6719C9.53748 18.9491 9.62589 19.2649 9.62924 19.5872V19.5872C9.62924 20.6438 10.4857 21.5003 11.5423 21.5003H12.7963C13.8493 21.5003 14.7043 20.6494 14.7093 19.5964V19.5964C14.7069 19.0883 14.9076 18.6003 15.2669 18.241C15.6262 17.8817 16.1143 17.6809 16.6224 17.6834C16.944 17.692 17.2584 17.78 17.5377 17.9396V17.9396C18.4505 18.4656 19.6167 18.1546 20.1464 17.244V17.244L20.8054 16.1456C21.0605 15.7077 21.1305 15.1863 21 14.6966C20.8694 14.207 20.549 13.7896 20.1098 13.5369V13.5369C19.6705 13.2842 19.3502 12.8668 19.2196 12.3772C19.089 11.8875 19.159 11.3661 19.4141 10.9282C19.58 10.6386 19.8202 10.3984 20.1098 10.2326V10.2326C21.0149 9.70314 21.3252 8.54374 20.8054 7.63301V7.63301V7.62386Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="12.1752"
				cy="11.8891"
				r="2.63616"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Wallet = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M22.1389 14.3962H18.0906C16.6042 14.3953 15.3993 13.1914 15.3984 11.7049C15.3984 10.2185 16.6042 9.01458 18.0906 9.01367H22.1389"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.549 11.6432H18.2373"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.24766 3H16.8911C19.7892 3 22.1388 5.34951 22.1388 8.24766V15.4247C22.1388 18.3229 19.7892 20.6724 16.8911 20.6724H8.24766C5.34951 20.6724 3 18.3229 3 15.4247V8.24766C3 5.34951 5.34951 3 8.24766 3Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.53516 7.53772H12.9341"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const Referral = ({ style }) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 36 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M18.6974 2.19727V5.72643"
				stroke="currentColor"
				strokeWidth="2.1875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6974 21.8984V24.8501"
				stroke="currentColor"
				strokeWidth="2.1875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6974 16.89V9.85938"
				stroke="currentColor"
				strokeWidth="2.1875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.7745 25.1654C28.4317 25.1654 30.5839 23.0399 30.5839 20.4158V16.635C28.8256 16.635 27.4096 15.2366 27.4096 13.5002C27.4096 11.7638 28.8256 10.3639 30.5839 10.3639L30.5824 6.58161C30.5824 3.95748 28.4286 1.83203 25.773 1.83203H6.22815C3.57249 1.83203 1.41876 3.95748 1.41876 6.58161L1.41724 10.487C3.17551 10.487 4.59156 11.7638 4.59156 13.5002C4.59156 15.2366 3.17551 16.635 1.41724 16.635V20.4158C1.41724 23.0399 3.56945 25.1654 6.22663 25.1654H25.7745Z"
				stroke="currentColor"
				strokeWidth="2.1875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
