import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import ActivitySpinner from "../shared/ActivitySpinner";

const UserProfile = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Profile" */ '../components/userDetails/MyProfile')
);
const UserMessages = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Messages" */ '../components/userDetails/Messages')
);
const UserSavedItems = lazy(() =>
	import(/* webpackChunkName: "UserDetails.SavedItems" */ '../components/userDetails/SavedItems')
);
const UserOrders = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Orders" */ '../components/userDetails/MyOrders')
);
const WalletPage = lazy(() =>
	import(/* webpackChunkName: "User.Wallet" */ '../shared/WalletPage')
);
const UserNotifications = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Notifications" */ '../components/userDetails/UserNotifications')
);
const UserReferrals = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Referrals" */ '../components/userDetails/Referrals')
);
const UserSettings = lazy(() =>
	import(/* webpackChunkName: "UserDetails.Settings" */ '../components/userDetails/UserSettings')
);

const spinnerStyle = {
	height: '100vh',
};

const UserDashboardRoutes = [
    {
        path: "/user",
        exact: true,
        name: "RedirectToUserProfile",
        element: (<Navigate to={{ pathname: '/user/my-account' }} />)
    },
    {
        path: "my-account",
        name: "UserProfile",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserProfile />
			</Suspense>
        )
    },
    {
        path: "messages",
        name: "UserMessages",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserMessages />
			</Suspense>
        )
    },
    {
        path: "wishlist",
        name: "UserWishlist",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserSavedItems />
			</Suspense>
        )
    },
    {
        path: "my-orders",
        name: "UserOrders",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserOrders />
			</Suspense>
        )
    },
    {
        path: "wallet",
        name: "UserWallet",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<WalletPage />
			</Suspense>
        )
    },
    {
        path: "notifications",
        name: "UserNotifications",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserNotifications />
			</Suspense>
        )
    },
    {
        path: "referrals",
        name: "UserReferrals",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserReferrals />
			</Suspense>
        )
    },
    {
        path: "settings",
        name: "UserSettings",
        element: (
            <Suspense fallback={<ActivitySpinner size="large" style={spinnerStyle}/>}>
				<UserSettings />
			</Suspense>
        )
    }
]

export default UserDashboardRoutes;