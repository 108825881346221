import React from 'react';

export const UserIcon = ({ style, ...rest }) => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
            <rect width="29" height="29" fill="#E5E5E5"/>
            <g clipPath="url(#clip0_0_1)">
                <rect width="1512" height="2109" transform="translate(-1092 -29)" fill="white"/>
                <g clipPath="url(#clip1_0_1)">
                    <rect width="1508" height="87" transform="translate(-1090 -29)" fill="#FEFEFE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.983 17.3462C11.1154 17.3462 7.8125 17.931 7.8125 20.2729C7.8125 22.6148 11.0944 23.2205 14.983 23.2205C18.8506 23.2205 22.1525 22.6348 22.1525 20.2938C22.1525 17.9529 18.8715 17.3462 14.983 17.3462Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9829 14.0059C17.521 14.0059 19.5782 11.9478 19.5782 9.40969C19.5782 6.8716 17.521 4.81445 14.9829 4.81445C12.4448 4.81445 10.3867 6.8716 10.3867 9.40969C10.3782 11.9392 12.422 13.9973 14.9506 14.0059H14.9829Z" stroke="currentColor" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="14.5" cy="14.5" r="14" stroke="currentColor"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.983 17.3462C11.1154 17.3462 7.8125 17.931 7.8125 20.2729C7.8125 22.6148 11.0944 23.2205 14.983 23.2205C18.8506 23.2205 22.1525 22.6348 22.1525 20.2938C22.1525 17.9529 18.8715 17.3462 14.983 17.3462Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9829 14.0059C17.521 14.0059 19.5782 11.9478 19.5782 9.40969C19.5782 6.8716 17.521 4.81445 14.9829 4.81445C12.4448 4.81445 10.3867 6.8716 10.3867 9.40969C10.3782 11.9392 12.422 13.9973 14.9506 14.0059H14.9829Z" stroke="currentColor" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="14.5" cy="14.5" r="14" stroke="currentColor"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="1512" height="2109" fill="white" transform="translate(-1092 -29)"/>
                </clipPath>
                <clipPath id="clip1_0_1">
                    <rect width="1508" height="87" fill="white" transform="translate(-1090 -29)"/>
                </clipPath>
            </defs>
        </svg>

    )
}