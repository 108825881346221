import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';

const styles = {
	Wrapper: styled.div`
		.alice-carousel {
			/* margin: 2em 0; */
			padding: 1em 0;
		}

		.alice-carousel__wrapper {
			padding: 1em 0;
		}
		.alice-carousel__stage-item {
			/* margin: 0 10px; */
		}
		.alice-carousel__prev-btn,
		.alice-carousel__next-btn {
			text-align: right;
			position: absolute;
			top: 50%;
			width: unset;
		}
		.alice-carousel__prev-btn-wrapper,
		.alice-carousel__next-btn-wrapper {
			border-radius: 50%;
			width: 33px;
			height: 33px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.alice-carousel__prev-btn-wrapper:hover,
		.alice-carousel__next-btn-wrapper:hover {
			background: var(--grey-6);
		}
		.alice-carousel__prev-btn {
			left: 0;
		}
		.alice-carousel__next-btn {
			right: 0;
		}
	`,
};

const { Wrapper } = styles;

const Slider = ({ items, itemCount, onSlideChange, style, ...rest }) => {
	return (
		<Wrapper style={style}>
			<AliceCarousel
				items={items}
				autoPlay={true}
				autoPlayInterval={4000}
				disableAutoPlayOnAction={true}
				disableDotsControls={true}
				infinite={true}
				// innerWidth={1}
				onSlideChange={onSlideChange}
				responsive={{
					0: { items: 1 },
					600: {
						items: 1,
					},
					750: {
						items: 2
					},
					1024: {
						items: 3,
					},
					1200: {
						items: itemCount,
					},
				}}
				{...rest}
			/>
		</Wrapper>
	);
};

Slider.propTypes = {
	items: PropTypes.arrayOf(PropTypes.element).isRequired,
	itemCount: PropTypes.number.isRequired,
	onSlideChange: PropTypes.func,
	style: PropTypes.object,
	rest: PropTypes.any,
};

Slider.defaultProps = {
	itemCount: 1,
};

export default Slider;
