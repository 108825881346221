const currencyOptions = {
	minimumFractionDigits: 0,
	MaximumFractionDigits: 2,
};

export const getPrice = (price = 0) => {
	return price.toLocaleString(undefined, currencyOptions);
};

export const getParams = (params = {}) => ({
	sort: params?.sort,
	per_page: params?.pageSize,
	page: params?.current,
	search: params?.search
});

export function range(start, end, step = 1) {
	const len = Math.floor((end - start) / step) + 1
	return Array(len).fill().map((_, idx) => start + (idx * step))
}

export const renderProductImage = (image = null, options = "") => image ? `https://res.cloudinary.com/ddble5id6/image/fetch/${options}${image}` : `https://res.cloudinary.com/dcloudcdn/image/upload/${options}v1686682007/copnow-media/default-image_mip5kq.png`;

export const formReducer = (state, event = null) => {
	if (!event) {
		return {};
	}
	if (event.type === 'add') {
		return {
			...state,
			...event.value,
		};
	}
	return {
		...state,
		[event.name]: event.value,
	};
};

export const phoneNumberFormatter = (number = 0) => {
	let validNumberType = number && typeof number === 'number';
	let validLength = number && number.toString().length === 10;
	if (!number || !validNumberType || !validLength) {
		//return null so validations will fail if needed
		return null;
	}

	return `+234${number}`;
};

export const convertToPhoneNumber = (numberString = '') => {
	let validLength = numberString && numberString.length === 14;
	let validType = numberString && typeof numberString === 'string';

	if (!numberString || !validLength || !validType) {
		return 0;
	}

	return parseInt(numberString.slice(4));
};

export const nairaToKobo = (amount) => {
	return (amount * 100).toFixed(0);
};

export const isEmailValid = (email) => {
	const regex = /\S+@\S+\.\S+/;
	return (email && (email.length > 0) && regex.test(email));
};

export const passwordRegex =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#._\$%\^&\*])(?=.{8,})/;

export const cloundinaryTransform =
	'https://res.cloudinary.com/dcloudcdn/image/fetch/';

export const sanitizeUrlString = (str) => {
	const regex = /[^\w\s]/g;
	return str.toLowerCase().replace(regex, '').split(' ').join('-');
};
