import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper } from './styles';
import handbag from '../../assets/icons/404/404-handbag.png';
import shoes from '../../assets/icons/404/404-shoes.png';
import jacket from '../../assets/icons/404/404-jacket.png';
import watch from '../../assets/icons/404/404-watch.png';
import glasses from '../../assets/icons/404/404-glasses.png';

const PageNotFound = () => {

    return ( 
        <Wrapper>
            <h1>404</h1>
            <h5>Ooops, page not found!</h5>
            <div className='handbag-logo'>
                <img src={handbag} alt="handbag"/>
            </div>
            <div className='shoes-logo'>
                <img src={shoes} alt="shoes"/>
            </div>
            <div className='jacket-logo'>
                <img src={jacket} alt="jacket"/>
            </div>
            <div className='watch-logo'>
                <img src={watch} alt="wristwatch"/>
            </div>
            <div className='glasses-logo'>
                <img src={glasses} alt="sunglasses"/>
            </div>
            <p>
                This is not your fault, we are currently working on <br/> 
                more items to make your experince a pleasant one
            </p>
            <Link className='button' to="/">
                Go Home
            </Link>
        </Wrapper>
     );
}
 
export default PageNotFound;