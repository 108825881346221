import React, { Fragment } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import Context from '../contexts/Context';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Routes from '../routes/Routes';

function App() {
	return (
		<div className="App">
			<Fragment>
				<Context>
					<Header />
					<Routes />
					<Footer />
				</Context>
			</Fragment>
			<ToastContainer position="top-center" theme="colored" autoClose={2000} />
		</div>
	);
}

export default App;
