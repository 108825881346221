import { useState } from 'react';
import PropTypes from 'prop-types';
import VendorSearchResultCard from './VendorSearchResultCard';
import { VendorSearchResultsWrapper } from '../../styles';

const resultsPerRow = 4;

const VendorSearchResults = ({ results }) => {
	const [next, setNext] = useState(resultsPerRow);

	const handleMoreResults = () => {
		setNext(next + resultsPerRow);
	};

	return (
		<VendorSearchResultsWrapper>
			<p className="showing-text">
				showing: <span>{results.length}</span> result(s) found
			</p>
			<div className="vendor-search-results-grid">
				{results.length > 0 ? (
					results
						?.slice(0, next)
						?.map((vendorResult) => (
							<VendorSearchResultCard
								key={vendorResult.id}
								vendorDetails={vendorResult}
							/>
						))
				) : (
					<p>no vendor found</p>
				)}
			</div>
			{next < results?.length && (
				<div className="show-all-results">
					<button onClick={handleMoreResults}>see more</button>
				</div>
			)}
		</VendorSearchResultsWrapper>
	);
};

export default VendorSearchResults;

// PROPTYPES
VendorSearchResults.propTypes = {
	results: PropTypes.array,
};
