import styled from 'styled-components'

export const Wrapper = styled.div`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    h1 {
        color: var(--blue-3);
        font-size: 13em;
        letter-spacing: 2rem;
        margin: 1rem 0;
        font-weight: 600;
    }
    h5 {
        color: var(--grey-5);
        font-size: 1.8em;
        font-weight: 500;
    }
    p {
        color: var(--grey-5);
        font-size: 1.2em;
    }
    .button {
        background: var(--blue-3);
        color: white;
        border: none;
        padding: 0.5em;
        width: 200px;
        border-radius: 0.5em;
        transition: 0.5s ease;
        font-size: large;
        margin: 1em 0;
        font-weight: 600;
        text-align: center;
    }
    .button:hover {
        transform: scale(1.02);
    }
    .handbag-logo,
    .shoes-logo,
    .jacket-logo,
    .watch-logo,
    .glasses-logo {
        position: absolute;
    }
    .handbag-logo {
        left: 0;
        bottom: 0;
    }
    .shoes-logo {
        left: 0;
        top: 100px;
    }
    .jacket-logo {
        bottom: 0;
        right: 0;
    }
    .watch-logo {
        right: 0;
        bottom: 200px;
    }
    .glasses-logo {
        top: 50px;
        right: 0;
    }
`