// import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
// import VendorService from '../../services/VendorService';
// import ActivitySpinner from '../ActivitySpinner';
import { StyledWrapper } from './styles';

import sample1 from '../../assets/images/vendor_banner/1.jpg';
import sample2 from '../../assets/images/vendor_banner/2.jpg';
import sample3 from '../../assets/images/vendor_banner/3.jpg';
import sample4 from '../../assets/images/vendor_banner/4.jpg';

const vendorAds = [
	{
		img: sample1,
		store_name: '7universe',
		url: 'https://copnow.co/vendors/7universe6',
	},
	{
		img: sample2,
		store_name: 'blow your mind world',
		url: 'https://copnow.co/vendors/Blow_Your_Mind_World5',
	},
	{
		img: sample3,
		store_name: 'fabhouse by annie',
		url: 'https://copnow.co/vendors/Fabhouse_by_Annie3',
	},
	{
		img: sample4,
		store_name: '7universe',
		url: 'https://copnow.co/vendors/7universe6',
	},
];

const VendorBanner = () => {
	// const [vendorsStores, setVendorsStores] = useState([]);
	// const [loading, setLoading] = useState(true);
	// let mounted = useRef(true)

	// useEffect(() => {
	// 	mounted.current = true;

	// 	// async function fetchVendorsStores() {
	// 	// 	const { data } = await getVendorStores();

	// 	// 	if (mounted.current && data) {
	// 	// 		setVendorsStores(data);
	// 	// 	}
	// 	// 	setLoading(false);
	// 	// };

	// 	// fetchVendorsStores();

	// 	return () => (mounted.current = false);
	// }, []);

	// if (loading) return <ActivitySpinner size="medium" />;

	return (
		<StyledWrapper>
			<div className="banner-heading">
				<h2>it's all new, it all arrived this week</h2>
				<p>
					Join million of people using Copnow for swift shopping and delivery.
					get free delivery on selected items this summer
				</p>
				{/* <Link to="#">shop now</Link> */}
			</div>

			<div className="banner-grid">
				<Swiper
					modules={[Pagination, Autoplay]}
					slidesPerView={1}
					spaceBetween={50}
					pagination={{
						clickable: true,
					}}
					loop={true}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					breakpoints={{
						640: {
							slidesPerView: 1,
						},
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
					}}
				>
					{vendorAds.map((vendorAd, index) => (
						<SwiperSlide key={index}>
							<div className="banner-grid-item">
								<img src={vendorAd.img} alt={vendorAd.store_name} />
								<div className="overlay-section">
									<a href={vendorAd.url} rel="noreferrer noopenner">
										view store
									</a>
									{/* <Link to={vendorAd.url}>view store</Link> */}
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</StyledWrapper>
	);
};

export default VendorBanner;
