import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import Slider from './Slider';
import ProductCard from './ProductCard';
import LandingPageService from '../services/LandingPageService';
import { Skeleton } from 'antd';

const { getTopItems } = LandingPageService();

const styles = {
	Wrapper: styled.div`
		width: 100%;
		padding: 2em 0;
		margin: 0.5em 0.1em;
		h4 {
			font-weight: 600;
			margin: 0.5em;
		}
		p {
			margin: 0.5em;
		}

		@media screen and (min-width: 1024px) {
			margin: 0.5em;
		}
	`,
	Div: styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	`,
};

const { Wrapper, Div } = styles;

const convertItems = (items = []) => {
	return items.map((item, index) => <ProductCard product={item} key={index} />);
};

const TopItems = ({ onSlideChange }) => {
	const [loading, setLoading] = useState(true);
	const [topItems, setTopItems] = useState([]);
	let mounted = useRef(true);
	let dataFetched = useRef(false);

	useEffect(() => {
		mounted.current = true;
		if (dataFetched.current) return;

		async function fetchItems() {
			const topItemsResponse = await getTopItems();

			if (topItemsResponse && mounted.current) {
				const { data } = topItemsResponse;
				setTopItems(convertItems(data));
			}
			dataFetched.current = false;
			setLoading(false);
		}

		fetchItems();
		dataFetched.current = true;

		return () => {
			setTopItems([]);
			setLoading(true);
			mounted.current = false;
		};
	}, []);

	return loading ? (
		<Skeleton active className="my-3 p-3" />
	) : topItems.length ? (
		<Wrapper>
			<h4 style={{ textAlign: 'center' }}>Top Products</h4>
			<Div>
				{/* <p>Find products you'll love. From vendors near you. Only on Copnow.</p> */}
				{/* <Link to="/">See All</Link> */}
			</Div>

			<Slider itemCount={4} items={topItems} onSlideChange={onSlideChange} />
		</Wrapper>
	) : (
		<></>
	);
};

TopItems.propTypes = {
	onSlideChange: PropTypes.func,
};

export default TopItems;
