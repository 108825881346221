import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ProductSearchResults from './ProductSearchResults/ProducSearchResults';
import VendorSearchResults from './VendorSearchResults/VendorSearchResults';
import { SearchResultsWrapper } from '../styles';

const QUERY_OPTION_MAP = {
	products: ProductSearchResults,
	vendor: VendorSearchResults,
};

const SearchResults = ({ queryOption, results, setShowResults }) => {
	const ResultsComponent = QUERY_OPTION_MAP[queryOption];
	const searchRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (searchRef.current && !searchRef.current.contains(e.target)) {
				setShowResults(false);
			}
		};

		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, [setShowResults]);

	return (
		<SearchResultsWrapper ref={searchRef}>
			<ResultsComponent results={results} />
		</SearchResultsWrapper>
	);
};

export default SearchResults;

// PROPTYPES
SearchResults.propTypes = {
	queryOption: PropTypes.string,
	results: PropTypes.array,
	setShowResults: PropTypes.func,
};
