import Axios from "./Axios";
import { Error } from "../shared/Alerts"

function CategoryService() {
    const url = '/category'

    const getCategories = async (params = {}) => {
        try {
            const { data } = await Axios.get('/categoryList', { params })
            return data
        } catch (error) {
            Error(error, {}, "Unable to get categories. Please contact support.")
            return false;
        }
    }

    const createCategory = async params => {
        const { category_name } = params
        if (!category_name) {
            Error(null, {}, "Category name is required.")
            return false
        }
        try {
            const { data } = await Axios.post(url + '/store', params)
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }
    
    const updateCategory = async (categoryId, params) => {
        const { category_name } = params
        if (!categoryId || !category_name) {
            Error(null, {}, "Invalid params passed.")
            return false;
        }
        try {
            const { data } = await Axios.put(url + `/updateCategory/${categoryId}`, params)
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const deleteCategory = async categoryId => {
        if (!categoryId) {
            Error(null, {}, "Category ID is required.")
            return false
        }
        try {
            const { data } = await Axios.delete(url + `/deleteCategory/${categoryId}`)
            return data
        } catch (error) {
            Error(error)
            return false;
        }
    }

    const getCategory = async categoryId => {
        if (!categoryId) {
            Error(null, {}, "Category ID is required.")
            return false
        }
        try {
            const { data } = await Axios.get(url + `/${categoryId}`)
            return data
        } catch (error) {
            Error(error, {}, "Error fetching category detail.")
            return false;
        }
    }
    
    return {
        getCategories, createCategory,
        updateCategory, deleteCategory,
        getCategory
    }
}

export default CategoryService