import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {
	HeroSectionWrapper,
	AdvertOverlay,
	HeroCarousel,
} from '../../views/Homepage/styles';

const styles = {
	overlay: {
		opacity: '0.2',
	},
};

const HeroSection = () => {
	return (
		<HeroSectionWrapper>
			<div className="left">
				<AdvertOverlay style={styles.overlay} />
				<h1>new collection</h1>
				<p>
					Shop from fashion collections <br /> from over 20 businesses today
				</p>
				<button>SHOP NOW</button>
			</div>
			<div className="right">
				<div className="top">
					<AdvertOverlay style={styles.overlay} />
					<h3>Let&apos;s change your experience</h3>
					<small>
						Join Copnow and let&apos;s redefine <br /> your shopping experience
						with a world-class taste
					</small>
				</div>
				<div className="bottom">
					<AdvertOverlay style={styles.overlay} />
					<h3>Gain more customers</h3>
					<small>
						Onboard your business now <br /> and drive your customer reach
					</small>
				</div>
			</div>
			<HeroCarousel>
				<Swiper
					modules={[Pagination, Autoplay]}
					slidesPerView={1}
					spaceBetween={50}
					pagination={{
						clickable: true,
					}}
					loop={true}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
				>
					<SwiperSlide>
						<div className="left slide-one">
							<AdvertOverlay style={styles.overlay} />
							<h1>new collection</h1>
							<p>
								Shop from fashion collections <br /> from over 20 businesses
								today
							</p>
							<button>SHOP NOW</button>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="top slide-two">
							<AdvertOverlay style={styles.overlay} />
							<h3>Let&apos;s change your experience</h3>
							<small>
								Join Copnow and let&apos;s redefine <br /> your shopping
								experience with a world-class taste
							</small>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="bottom slide-three">
							<AdvertOverlay style={styles.overlay} />
							<h3>Gain more customers</h3>
							<small>
								Onboard your business now <br /> and drive your customer reach
								up
							</small>
						</div>
					</SwiperSlide>
				</Swiper>
			</HeroCarousel>
		</HeroSectionWrapper>
	);
};

export default HeroSection;
